import { createSelector } from 'reselect';
import { INSTANCE_SK } from '../../browser/browserConfig';

const getRegistration = state => state.registration;

export const selectRegistrationProductCode = createSelector([getRegistration], reg => reg.product_code);
export const selectRegistrationAgentureCode = createSelector([getRegistration], reg => reg.agentureCode);

export const selectSelectedAccount = createSelector([getRegistration], reg => +reg.selectedBankAccountIndex === 0 ? {
	k01_prefix: reg.payoutAccountPrefix,
	k01_number: reg.payoutAccount,
	k10_bank_code: reg.payoutAccountPost,
	k01_iban: reg.payoutAccountIBAN,
} : reg.bankAccountsFromAPI[+reg.selectedBankAccountIndex]);


export const selectDiscountSettingEnabled = createSelector([getRegistration], reg => INSTANCE_SK && reg.wrongAgentureCode === false && !!reg.agentureCode);
