import * as actions from './actions';
import { Record } from '../transit';

const InitialState = Record({
  showSpinner: false,
}, 'app');

export default function appReducer(state = new InitialState(), action) {
  switch (action.type) {
    case actions.TOGGLE_SPINNER:
      return state.update('showSpinner', value => !value);
    default:
      return state;
  }
}
