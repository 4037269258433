import { Record } from '../transit';

const Person = Record({
  birthDate: '',
  firstName: '',
  lastName: '',
  email: '',
  telephone: '',
  isDeputy: false,
  onlyForShop: false,
  selectedMe: false,
  onlyDeputy: false,
  addedByHand: false //can be deleted
}, 'person');

export default Person;
