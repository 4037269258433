import { INSTANCE } from '../browser/browserConfig';

export const INSTANCES = {
  cz: {
    phonePrefixDefault: '+420',
    defaultMapCenter: { lat: 50.084627, lng: 14.42237 },
    geosuggestBounds: undefined,
  },
  sk: {
    phonePrefixDefault: '+421',
    defaultMapCenter: { lat: 48.149099, lng: 17.107760 },
    geosuggestBounds: { south: 47.610193, west: 16.713680, north: 49.626486, east: 22.932663 },
  }
};

export const phonePrefixDefault = INSTANCES[INSTANCE].phonePrefixDefault;
export const defaultMapCenter = INSTANCES[INSTANCE].defaultMapCenter;
export const geosuggestBounds = INSTANCES[INSTANCE].geosuggestBounds;

export const banks = [
  {
    label: '',
    value: 0
  },
  {
    label: 'Česká spořitelna/Global Payments Europe (GPE)',
    value: 1
  },
  {
    label: 'ČSOB',
    value: 2
  },
  {
    label: 'Fio Banka',
    value: 3
  },
  {
    label: 'Komerční banka/Atos',
    value: 4
  },
  {
    label: 'Reiffeisen Banka/Revo',
    value: 5
  },
  {
    label: 'Unicredit Bank',
    value: 6
  },
];

export const mapStyles = [
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off"
      }
    ]
  }, {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      }, {
        lightness: 65
      }, {
        visibility: "on"
      }
    ]
  }, {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      }, {
        lightness: 51
      }, {
        visibility: "simplified"
      }
    ]
  }, {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      }, {
        visibility: "simplified"
      }
    ]
  }, {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      }, {
        lightness: 30
      }, {
        visibility: "on"
      }
    ]
  }, {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      }, {
        lightness: 40
      }, {
        visibility: "on"
      }
    ]
  }, {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      }, {
        visibility: "simplified"
      }
    ]
  }, {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        saturation: "8"
      }, {
        lightness: "1"
      }, {
        color: "#60a4c5"
      }
    ]
  }, {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        visibility: "on"
      }, {
        lightness: -25
      }, {
        saturation: -100
      }
    ]
  }
];
