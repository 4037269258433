// @flow
import React from "react";
import { Modal } from 'react-bootstrap';
import type { BankAccountRecord } from '../../../flow/model';
import { validateAccount } from '../../browserCommonFunctions';
import { BankAccountControl } from "./BankAccountControl";
import { INSTANCE_SK } from "../../browserConfig";

type Props = {
	show: boolean,
	showErrorHelpers: boolean,
	p: any,
	bankCodes: any[],
  onCancel: () => void,
  onAdd: (data: BankAccountRecord) => void
};

type State = {
  accountData: BankAccountRecord,
  showErrorHelpers: boolean,
};

export class AddBankAccountModal extends React.Component<Props, State> {
  state = {
    accountData: {
      k01_prefix: '',
      k01_number: '',
      k10_bank_code: '',
      k01_iban: '',
      own: true,
    },
    showErrorHelpers: false,
	};

  static defaultProps = {};

  reset() {
    this.setState({
      accountData: {
        k01_prefix: '',
        k01_number: '',
        k10_bank_code: '',
        k01_iban: '',
        own: true,
      },
      showErrorHelpers: false,
    });
  }

  handleCancel = () => {
    this.props.onCancel();
    this.reset();
  }

  handleAdd = () => {
    const data = this.state.accountData;
    if (validateAccount(data)) {
      this.props.onAdd(this.state.accountData);
      setTimeout(() => {
        this.reset();
      }, 150);
    } else {
      this.setState({ showErrorHelpers: true });
    }
  }

  render() {
    const { p, bankCodes, show } = this.props;
    const showErrorHelpers = this.props.showErrorHelpers || this.state.showErrorHelpers;
    return (
      <Modal show={show} onHide={this.handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{p.t("anotheraccount")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BankAccountControl
            data={this.state.accountData}
            showErrorHelpers={showErrorHelpers}
            bankCodes={bankCodes}
            p={p}
            useIBAN={INSTANCE_SK}
            onChange={data => this.setState({ accountData: data })}
            onEnterKey={this.handleAdd}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary pull-right offset-left"
            onClick={this.handleAdd}
          >
            {p.t("add")}
          </button>
          <button className="btn btn-default" onClick={this.handleCancel}>
            {p.t("cancel")}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export function recountAccountPosts(oAccounts: { label: string, value: string }[]): { label: string, value: string }[] {
  return oAccounts.sort((a, b) => {
    // console.log('rovnam: ', a, b);
    if (a.value > b.value) {
      return 1;
    }
    else {
      return -1;
    }
  }).map(account => { return { value: account.value, label: account.value + ' ' + account.label }; });
}
