import Helmet from 'react-helmet';
import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { getP, getLocale } from 'redux-polyglot';
import classnames from 'classnames';
import { Modal, Glyphicon, Button, Col } from 'react-bootstrap'
import SubMenu from './SubMenu.react';
import { changeField, checkIBAN } from '../../common/registration/actions';
import { loadReslists } from '../../common/app/actions';
import { scrollTopOfForm, validateAccount, formatAccount, validateNumber, validateEmail, isFocusedInput } from '../browserCommonFunctions';
import { tRouteLocale } from '../createRoutes';
import { AddBankAccountModal } from './components/AddBankAccountModal';
import { BankAccountControl } from './components/BankAccountControl';
import { INSTANCE_SK, DEVELOPMENT } from '../browserConfig';
import { selectSelectedAccount, selectDiscountSettingEnabled } from '../../common/registration/selectors';
import FieldGroup from '../app/components/FieldGroup';

export class Payout extends Component {

  legacyRefs: {[ key:string ]: any} = {};

  state = {
    loading: false,
    showCommission: false,
    showDiscountSetting: false,
    showError: false,
    showAddAccount: false,
    showDeleteAccount: false,
    showDeleteAccountIndex: 0,
    showErrorHelpers: false,
    discountSettingPct: null,
    discountSettingError: null,
  };

  componentWillMount() {
    this.props.loadReslists();
  }

  componentDidMount() {
    if (DEVELOPMENT) {
      this.props.changeField({ name: 'startedPayout', value: true });
    }
    setTimeout(() => {
      if (!this.props.registration.startedPayout) {
        this.props.router.push('/');
      }
    }, 100);
  }

  toggle(whatToToggle) {
    this.setState({
      [whatToToggle]: !this.state[whatToToggle]
    });
  }

  toggleShowCommission = () => {
    this.setState({
      showCommission: !this.state.showCommission
    });
  }

  /** Calls check api and returns error message when account is invalid otherwise null */
  async validateIbanAsync(selectedAccount) {
    return this.props.checkIBAN(selectedAccount.k01_iban)
      .then(res => res.error ? (res.payload.response.message || this.props.p.t('validaccountnumber')) : null );
  }

  async onSubmit(e) {
    e.preventDefault();

    const { registration, p, currentLocale, selectedAccount } = this.props;
    this.setState({ loading: true });

    if (selectedAccount.k01_iban.length === 0 &&
      (selectedAccount.k01_number.length === 0 || selectedAccount.k10_bank_code.length === 0)
    ) {
      this.props.changeField({ name: 'showError', value: true });
      this.props.changeField({ name: 'showErrorText', value: p.t('validaccountnumber') });
      this.props.changeField({ name: 'showErrorHelpers', value: true });
      scrollTopOfForm();
      this.setState({ showError: true });
    } else {
      let accountValid = true;
      let errorMessage = p.t('validaccountnumber');

      // validate entered account?
      if (registration.bankAccountsFromAPI.length === 0) {
        accountValid = validateAccount(selectedAccount);

        if (accountValid && selectedAccount.k01_iban.length !== 0) {
          // IBAN? => check async validation
          errorMessage = await this.validateIbanAsync(selectedAccount);
          accountValid = !errorMessage;
          // TODO: add message to field? => state.errors? async validation at level field? => redux-form?
        }
      }
      
      if (!accountValid) {
        this.props.changeField({ name: 'showError', value: true });
        this.props.changeField({ name: 'showErrorText', value: errorMessage });
        this.props.changeField({ name: 'showErrorHelpers', value: true });
        scrollTopOfForm();
      } else {
        this.props.changeField({ name: 'startedRecapitulation', value: true });
        this.props.changeField({ name: 'showError', value: false });
        this.props.changeField({ name: 'showErrorHelpers', value: false });
        this.props.router.push(tRouteLocale('/lang/registration/recapitulation', currentLocale));
      }
    }

    this.setState({ loading: false });
  }

  changeField = (oChange) => {
    this.props.changeField({ name: oChange.name, value: oChange.value });
  }

  handleMainAccountChange = (data) => {
    this.changeField({ name: 'payoutAccountPrefix', value: data.k01_prefix });
    this.changeField({ name: 'payoutAccount', value: data.k01_number });
    this.changeField({ name: 'payoutAccountPost', value: data.k10_bank_code });
    this.changeField({ name: 'payoutAccountIBAN', value: data.k01_iban });
  }

  showDiscountSetting(show: boolean) {
    this.setState({ showDiscountSetting: show, discountSettingPct: this.props.registration.sale_percent, discountSettingError: null });

    if (!show) {
      this.props.changeField({ name: 'showError', value: false });
    }
  }

  confirmDiscountSetting = () => {
    if (this.state.discountSettingError) {
      this.props.changeField({ name: 'showError', value: true });
      this.props.changeField({ name: 'showErrorText', value: this.state.discountSettingError });
      return;
    } else {
      this.props.changeField({ name: 'showError', value: false });
    }
    this.changeField({ name: 'sale_percent', value: this.state.discountSettingPct });
    this.showDiscountSetting(false);
  }

  render() {
    const { location, registration, p, currentLocale, discountSettingEnabled } = this.props;
    const shoppath = tRouteLocale('/lang/registration/shops', currentLocale);
    const mainAccountData = {
      k01_prefix: registration.payoutAccountPrefix,
      k01_number: registration.payoutAccount,
      k10_bank_code: registration.payoutAccountPost,
      k01_iban: registration.payoutAccountIBAN,
    };

    const sale_percent = discountSettingEnabled && registration.sale_percent ? registration.sale_percent : false;

    return <main id="content" role="main" className="site-content bg-gray-lighter">
        <Helmet title={p.t("metapayout")} />

        <div className="section-content">
          <div className="container">
            {/* Progress */}
            <SubMenu location={location} />

            <hr className="offset-top-none offset-bottom-large-xs-max offset-bottom-large" />

            <form onSubmit={this.onSubmit.bind(this)}>
              <div className="row offset-bottom-large">
                <div className="col-sm-6 offset-bottom-large-xs-max">
                  {/* Pay out frequency */}
                  <h2 className="offset-top-none">{p.t("wishpayout")}</h2>

                  <div className="form-group form-offset-top offset-bottom-large">
                    <div className="btn-group-block btn-group-block-responsive btn-group-lg-sm-min" role="group" aria-label="Frekvence vyplácení" data-toggle="buttons">
                      {registration.payment_frequency.map(
                        (frequency, index) => (
                          <label
                            key={frequency.id}
                            onClick={() =>
                              this.changeField({
                                name: "payoutType",
                                value: frequency.id,
                              })
                            }
                            className={classnames("btn btn-default", {
                              active: registration.payoutType === frequency.id
                            })}
                          >
                            <input
                              type="radio"
                              name="options"
                              id={"option" + frequency.id}
                              autoComplete="off"
                            />
                            <span className="btn-heading">
                              <span className="h2 text-light">
                                {frequency.frequency}
                                &nbsp;
                              </span>
                              <br className="hidden-xs" /> {frequency.unit}
                            </span>
                            {sale_percent ? (
                              <span>
                                {p.t("commissiondiscountline1", {
                                  sale_percent,
                                  provision: frequency.provision
                                })}
                                <br className="hidden-xs" />
                                {p.t("commissiondiscountline2", {
                                  sale_percent,
                                  provision: frequency.provision
                                })}
                              </span>
                            ) : (
                              <span>
                                {frequency.provision} %
                                <br className="hidden-xs" />
                                {p.t("commission")}
                              </span>
                            )}
                          </label>
                        )
                      )}
                    </div>
                  </div>

                  {/* More information */}
                  <p>
                    <span className="glyphicon glyphicon-info-sign icon-shift-down text-info offset-right" aria-hidden="true" />
                    <a href="#provisions" className="link offset-right" onClick={e => {
                        e.preventDefault();
                        this.toggleShowCommission();
                      }}>
                      {p.t("moreaboutprovisions")}
                    </a>
                    {discountSettingEnabled && <Glyphicon glyph="info-sign" className="icon-shift-down text-info offset-right" />}
                    {discountSettingEnabled && <a href="#discount" className="link" onClick={e => {
                          this.showDiscountSetting(true);
                          e.preventDefault();
                        }}>
                        {p.t("agenturediscountsetting")}
                      </a>}
                  </p>
                </div>
                <Col sm={6}>
                  <h2 className="offset-top-none">{p.t("toaccount")}</h2>
                  {registration.bankAccountsFromAPI.length === 0 && <BankAccountControl data={mainAccountData} showErrorHelpers={registration.showErrorHelpers} bankCodes={registration.bank_codes} p={p} useIBAN={INSTANCE_SK} onChange={this.handleMainAccountChange} />}

                  {registration.bankAccountsFromAPI.length > 0 && <div className="form-offset-top">
                      <div className="form-group">
                        <div className="btn-group-block btn-group-block-vertical" role="group" aria-label="Bankovní účet" data-toggle="buttons">
                          {registration.bankAccountsFromAPI.map(
                            (account, index) => {
                              if (account.type !== undefined) {
                                return null;
                              }

                              return (
                                <label
                                  key={account.k01_number + index}
                                  onClick={e => {
                                    this.changeField({
                                      name: "selectedBankAccountIndex",
                                      value: index
                                    });
                                  }}
                                  className={classnames("btn btn-default", {
                                    active:
                                      registration.selectedBankAccountIndex ===
                                      index
                                  })}
                                >
                                  <input
                                    type="radio"
                                    name="options"
                                    autoComplete="off"
                                    onChange={e => {
                                      this.changeField({
                                        name: "selectedBankAccountIndex",
                                        value: index
                                      });
                                    }}
                                  />
                                  {formatAccount(account)}
                                  {account.own !== undefined &&
                                    account.own && (
                                      <span
                                        className="glyphicon glyphicon-trash pull-right"
                                        aria-hidden="true"
                                        style={{ marginTop: 4 }}
                                        onClick={e => {
                                          this.setState({
                                            showDeleteAccountIndex: index,
                                            showDeleteAccount: true
                                          });
                                        }}
                                      />
                                    )}
                                </label>
                              );
                            }
                          )}
                        </div>
                      </div>
                      {registration.bankAccountsFromAPI.length > 0 && <div className="form-group">
                          <span className="btn btn-default btn-block" onClick={() => this.toggle("showAddAccount")}>
                            <span className="btn-icon-left">
                              <span className="glyphicon glyphicon-plus" aria-hidden="true" />
                              {p.t("anotheraccount")}
                            </span>
                          </span>
                        </div>}
                    </div>}

                  {registration.bankAccountsFromAPI.length > 0 && <p className="help-block">
                      {p.t("publicregister")}
                    </p>}

                  <h2 className="offset-top-none offset-bottom-small">{p.t('digitalBilling')}</h2>
                  <div className={classnames("form-group offset-left offset-right", {
                      "has-error":
                        !validateEmail(registration.r13_email) &&
                        registration.showErrorHelpers
                    })}>
                    <label htmlFor="r13_email" className={classnames("control-label-floating control-label-floating-thin",
                        { active: registration.r13_email.length > 0 || isFocusedInput("r13_email", "id")})}>
                      {p.t('email')}
                    </label>
                    <input
                      value={registration.r13_email}
                      onChange={e => this.changeField({ name: "r13_email", value: e.target.value })}
                      onFocus={() => { this.forceUpdate(); }}
                      onBlur={() => { this.forceUpdate(); }}
                      name="r13_email"
                      type="email"
                      id="r13_email"
                      className="form-control form-control-thin"
                    />
                    {!validateEmail(registration.r13_email) && registration.showErrorHelpers && <div className="help-block">
                          {p.t("inputvalidemail")}
                        </div>}
                  </div>
                </Col>
              </div>
              {/* .row */}

              <hr className="offset-bottom-xs-max offset-bottom-large" />

              {/* Navigation */}
              <div className="row">
                <div className="col-sm-5 col-sm-push-7 col-md-4 col-md-push-8 offset-bottom-xs-max">
                  <button type="submit" className="btn btn-accent btn-block btn-lg" disabled={this.state.loading}>
                    <span className="btn-icon-right">
                      {p.t("summary")}
                      <span className="glyphicon glyphicon-menu-right" aria-hidden="true" />
                    </span>
                  </button>
                </div>
                <div className="col-sm-5 col-sm-pull-5 col-md-4 col-md-pull-4">
                  <Link to={shoppath} className="btn btn-accent btn-accent-outline btn-block btn-lg btn-icon-left" disabled={this.state.loading}>
                    <span className="glyphicon glyphicon-menu-left" aria-hidden="true" />
                    {p.t("previous")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal show={this.state.showCommission} onHide={this.toggleShowCommission}>
          <Modal.Header closeButton>
            <Modal.Title>{p.t("moreaboutprovisions")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{p.t("provision")}</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.toggleShowCommission}>
              {p.t("close")}
            </button>
          </Modal.Footer>
        </Modal>

        {discountSettingEnabled && <Modal show={this.state.showDiscountSetting} onHide={() => this.showDiscountSetting(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{p.t("agenturediscountsetting")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FieldGroup label={p.t("entercommissiondiscount")} type="number" value={this.state.discountSettingPct} onChange={val => this.setState(
                    {
                      discountSettingPct: val,
                      discountSettingError: validateNumber(
                        val,
                        1,
                        registration.maxSalePercent,
                        p.t("commissiondiscountinvalid")
                      )
                    }
                  )} error={this.state.discountSettingError} />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.showDiscountSetting(false)}>
                {p.t("cancel")}
              </Button>
              <Button bsStyle="primary" onClick={this.confirmDiscountSetting}>
                {p.t("confirm")}
              </Button>
            </Modal.Footer>
          </Modal>}

        <Modal show={this.state.showDeleteAccount} onHide={() => this.toggle("showDeleteAccount")}>
          <Modal.Body>{p.t("deleteaccount")}</Modal.Body>
          <Modal.Footer>
            <button ref={r => (this.legacyRefs["btn-remove-person"] = r)} className="btn btn-primary pull-right offset-left" onClick={() => {
                this.changeField({
                  name: "bankAccountsFromAPI",
                  value: registration.bankAccountsFromAPI.filter(
                    (a, index) =>
                      index !== this.state.showDeleteAccountIndex
                  )
                });
                if (this.state.showDeleteAccountIndex === registration.selectedBankAccountIndex) {
                  this.changeField({
                    name: "selectedBankAccountIndex",
                    value: 0
                  });
                }
                this.toggle("showDeleteAccount");
              }}>
              {p.t("delete")}
            </button>
            <button className="btn btn-default" onClick={() => this.toggle("showDeleteAccount")}>
              {p.t("cancel")}
            </button>
          </Modal.Footer>
        </Modal>

        <AddBankAccountModal show={this.state.showAddAccount} bankCodes={registration.bank_codes} showErrorHelpers={this.state.showErrorHelpers} onCancel={() => this.setState(
              { showErrorHelpers: false, showAddAccount: false }
            )} onAdd={data => {
            this.changeField({
              name: "bankAccountsFromAPI",
              value: registration.bankAccountsFromAPI.concat(data)
            });
            this.changeField({
              name: "selectedBankAccountIndex",
              value: registration.bankAccountsFromAPI.length
            });
            this.setState({ showAddAccount: false });
          }} p={p} />
      </main>;
  }
}

export default connect(state => ({
  registration: state.registration,
  discountSettingEnabled: selectDiscountSettingEnabled(state),
  p: getP(state, { polyglotScope: 'registration' }),
  currentLocale: getLocale(state),
  selectedAccount: selectSelectedAccount(state),
}), { changeField, checkIBAN, loadReslists })(Payout);
