// @flow
import React from "react";
import classnames from 'classnames';
import Select from 'react-select';
import type { BankAccountRecord } from '../../../flow/model';
import { validateAccount, isFocusedInput } from '../../browserCommonFunctions';

type Props = {
	showErrorHelpers: boolean,
	p: any,
  bankCodes: any[],
  data: BankAccountRecord,
  useIBAN: boolean,
  onChange: (data: BankAccountRecord) => void,
  onEnterKey?: () => void,
};

type State = {
};

export class BankAccountControl extends React.Component<Props, State> {
  static defaultProps = {
    onEnterKey: () => {},
    bankCodes: [],
  };

  handleChange = (prop: string, value: string) => {
    const newData = {
      ...this.props.data,
      [prop]: value,
    };

    this.props.onChange(newData);
  }

  renderCZ() {
    const { p, bankCodes, showErrorHelpers, data } = this.props;
    const hasError = showErrorHelpers && (data.k10_bank_code.length === 0 || data.k01_number.length === 0 || !validateAccount(data));
    return ([
        <div key={1} className={classnames("account offset-bottom-small", { "has-error": hasError })}>
          <div className="offset-left">
            <label
              className={classnames("control-label-floating control-label-floating-thin", {
                active: data.k01_prefix.length > 0 || isFocusedInput("account[prefixadd]", "id")
              })}
              htmlFor="account[prefixadd]"
            >
              {p.t("accountprefix")}
            </label>
            <input
              type="text"
              value={data.k01_prefix}
              onChange={e => this.handleChange('k01_prefix', e.target.value )}
              onFocus={() => this.forceUpdate()}
              onBlur={() => this.forceUpdate()}
              name="payoutAccountPrefix"
              className="form-control form-control-thin"
              id="account[prefixadd]"
              onKeyPress={e => { if (e.charCode === 13) this.props.onEnterKey(); }}
            />
          </div>
          <span
            className="text-center"
            style={{ top: -13, position: "relative" }}
          >
          —
          </span>
          <div className="account-wide offset-right">
            <label
              className={classnames("control-label-floating control-label-floating-thin", {
                active: data.k01_number.length > 0 || isFocusedInput("account[numberadd]", "id") }
              )}
              htmlFor="account[numberadd]"
            >
              {p.t("account")}
            </label>
            <input
              type="text"
              value={data.k01_number}
              onFocus={() => this.forceUpdate()}
              onBlur={() => this.forceUpdate()}
              name="payoutAccount"
              onChange={e => this.handleChange('k01_number', e.target.value )}
              className="form-control form-control-thin"
              id="account[numberadd]"
              onKeyPress={e => { if (e.charCode === 13) this.props.onEnterKey(); }}
            />
          </div>
        </div>,
        <div key={2} className="account offset-bottom">
          <div className="offset-left offset-right">
            <Select
              className="Select-form"
              valueRenderer={e => <div>{e.label}</div>}
              options={recountAccountPosts(bankCodes)}
              placeholder={p.t("bankcode")}
              value={data.k10_bank_code}
              onChange={e => this.handleChange('k10_bank_code', e.value || '' )}
              resetValue=""
            />
          </div>
        </div>,
        showErrorHelpers && hasError && <div key={3} className="form-group has-error">
          <p className=" help-block">{p.t("validaccountnumber")}</p>
        </div>
      ]);
  }

  renderIBAN() {
    const { p, showErrorHelpers, data } = this.props;
    const hasError = showErrorHelpers && (data.k01_iban.length === 0 || !validateAccount(data));
    return ([
        <div key={1} className={classnames("account offset-bottom", { "has-error": hasError })}>
          <div className="offset-left offset-right">
            <label
              className={classnames("control-label-floating control-label-floating-thin", {
                active: data.k01_iban.length > 0 || isFocusedInput("account[iban]", "id")
              })}
              htmlFor="account[iban]"
            >
              {p.t("account")}
            </label>
            <input
              type="text"
              value={data.k01_iban}
              onChange={e => this.handleChange('k01_iban', e.target.value )}
              onFocus={() => this.forceUpdate()}
              onBlur={() => this.forceUpdate()}
              className="form-control form-control-thin"
              id="account[iban]"
              onKeyPress={e => { if (e.charCode === 13) this.props.onEnterKey(); }}
            />
          </div>
        </div>,
        showErrorHelpers && hasError && <div key={3} className="form-group has-error">
          <p className=" help-block">{p.t("validaccountnumber")}</p>
        </div>
      ]);
  }

  render() {
    if (this.props.useIBAN) {
      return this.renderIBAN();
    }

    return this.renderCZ();
  }
}

export function recountAccountPosts(oAccounts: { label: string, value: string }[]): { label: string, value: string }[] {
  return oAccounts.sort((a, b) => {
    // console.log('rovnam: ', a, b);
    if (a.value > b.value) {
      return 1;
    }
    else {
      return -1;
    }
  }).map(account => { return { value: account.value, label: account.value + ' ' + account.label }; });
}
