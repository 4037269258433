import React from "react";
import { Route, IndexRedirect, Redirect } from "react-router";
import { useScroll } from "react-router-scroll";
import { Router, applyRouterMiddleware, browserHistory } from "react-router";
// import { connect } from "react-redux";
// import { getP, getLocale } from "redux-polyglot";
import App from "./app/App.react";
import Home from "./home/HomePage.react";
import NotFound from "./home/NotFound.react";
import RegistrationPage from "./registration/RegistrationPage.react";
import RegistrationBasic from "./registration/RegistrationBasic.react";
import Shops from "./registration/Shops.react";
import Payout from "./registration/Payout.react";
import Recapitulation from "./registration/Recapitulation.react";
import Finished from "./registration/Finished.react";
import RegistrationForIC from "./registration/RegistrationForIC.react";
import { routeMessages } from "./messages";
import MyStayFormContainer from "./mystay/MyStayFormContainer";
import IbmMothersFormContainer from "./mystay/IbmMothersFormContainer";
import AcceptanceMethodsFormContainer from "./mystay/AcceptanceMethodsFormContainer";

const tRouteInternal = (url, useT) =>
  url.replace(/[^)(/]+/g, part => {
    // do not translate parts with : (:variables), * and numeric parts (ids)
    if (
      part.length === 0 ||
      part.includes(":") ||
      ["*", "undefined", "null"].includes(part) ||
      /^[0-9]+$/.test(part)
    ) {
      return part;
    }

    const qIdx = part.indexOf("?");

    // translate without query string
    if (qIdx !== -1) {
      // routesParts.push(part.substr(0, qIdx));
      return `${useT(part.substr(0, qIdx))}${part.substr(qIdx)}`;
    }

    // routesParts.push(part);
    return useT(part);
  });

const MultiT = (key, locale) => {
  if (!locale) { throw new Error('Locale not defined'); }
  return (routeMessages[locale][key] || key);
};

export const tRouteLocale = (url, locale) => tRouteInternal(url, k => MultiT(k, locale));

export const Routes = () => {
  // TODO: render from Object.keys(routeMessages)...
  return (
    <Router
      history={browserHistory}
      render={applyRouterMiddleware(useScroll())}
    >
      <Route component={App} path="/">
        <IndexRedirect to="sk" />
        <Redirect from="ibm-moms" to={`en/ibm-moms`} push={false} />
        <Redirect from="ibm-mamicky" to={`sk/ibm-mamicky`} push={false} />
				<Redirect from="ibm-bez-karty" to="sk/ibm-bez-karty" push={false} />
				<Redirect from="ibm-no-card" to="en/ibm-no-card" push={false} />
				<Redirect from="sk/ibm-mamicky" to="sk/ibm-bez-karty" push={false} />
				<Redirect from="en/ibm-moms" to="en/ibm-no-card" push={false} />
        <Route component={Home} path="sk" />
        <Route component={props => <MyStayFormContainer {...props} type={'mystay'} />} path="sk/dotaznik" />
        <Route component={props => <MyStayFormContainer {...props} type={'rp'} />} path="sk/dotaznik-rp" />
        <Route component={props => <MyStayFormContainer {...props} type={'rp-client'} />} path="sk/dotaznik-rp-klient" />
				<Route component={IbmMothersFormContainer} path="sk/ibm-bez-karty" />
        <Route component={AcceptanceMethodsFormContainer} path="sk/metody-akceptacie" />
        <Route component={RegistrationForIC} path="sk/registracia" />
        <Route component={RegistrationPage} path="sk/registracia">
          <Route component={RegistrationBasic} path="zakladne-udaje" />
          <Route component={Shops} path="prevadzkarne" />
          <Route component={Payout} path="vyplateni" />
          <Route component={Recapitulation} path="shrnutie" />
          <Route component={Finished} path="odoslane" />
        </Route>
        <Route component={Home} path="en" />
				<Route component={IbmMothersFormContainer} path="en/ibm-no-card" />
        <Route component={AcceptanceMethodsFormContainer} path="en/acceptance-methods" />
        <Route component={RegistrationForIC} path="en/registration" />
        <Route component={RegistrationPage} path="en/registration">
          <Route component={RegistrationBasic} path="basic-data" />
          <Route component={Shops} path="shops" />
          <Route component={Payout} path="payout" />
          <Route component={Recapitulation} path="recapitulation" />
          <Route component={Finished} path="posted" />
        </Route>
        <Route component={NotFound} path="*" />
      </Route>
    </Router>
  );
};

// const mapStateToProps = state => ({
//   currentLocale: getLocale(state),
//   p: getP(state, { polyglotScope: "routes" })
// });

// const mapDispatchToProps = {};

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Routes);
