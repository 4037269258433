// @flow
import * as actions from './actions';
import type { RecordOf } from 'immutable';
import { List } from 'immutable';
import { Record } from '../transit';
import { phonePrefixDefault } from '../constants';
import type { RegistrationState } from '../../flow/state';
import type { Actions } from './actions';
import Shop from './shop';
import Person from './person';

export const initialStatePJO: RegistrationState = {
  affil_types: {},
  agentureCode: '',
  agreementUrl: '',
  bank_codes: [],
  bankAccountsFromAPI: [],
  cash_registers: {},
  pos_banks: {},
  pos_so: {},
  city: '',
  conditionsUrl: '',
  deputyEmail: '',
  deputyFirstName: '',
  deputyLastName: '',
  dic: '',
  dic_vat: '',
  email: '',
  files: [],
  fullPower: false,
  fullPowerData: '',
  fullPowerName: '',
  googleAddress: {},
  googleLegalAddress: {},
  ic: '',
  name: '',
  originalLegalAddress: {},
  payment_frequency: [],
  payoutAccount: '',
  payoutAccountPost: '',
  payoutAccountPrefix: '',
  payoutAccountIBAN: '',
  payoutType: null,
  people: List(),
  postalCode: '',
  selectedBankAccountIndex: 0,
  shops: List(),
  showError: false, //main top alert
  showErrorClass: 'danger',
  showErrorHelpers: false, //helpers under inputs
  showErrorText: '',
  startedBasic: true,
  startedPayout: false,
  startedRecapitulation: false,
  startedShops: false,
  telephone: phonePrefixDefault,
  wholeAddress: '',
  wrongAgentureCode: false,
	product_code: null,
	rekreo_product: false,
  sale_percent: null,
  maxSalePercent: 10,
  r13_email: '',
};

export const InitialState: Class<RecordOf<RegistrationState>> = Record(initialStatePJO, 'registration');

export default function registrationReducer(state: RecordOf<RegistrationState> = new InitialState(), action: Actions): RecordOf<RegistrationState> {
  switch (action.type) {
    case actions.ADD_COMPANY_BY_IC: {
      const { oCompany } = action.payload;

      let payoutAccountPrefix = '';
      let payoutAccount = '';
      let payoutAccountPost = '';
      let payoutAccountIBAN = '';

      // sanitize account objects for easy check ".length" in rest of app
      const companyAccounts = !oCompany.bank_accounts ? [] : oCompany.bank_accounts.map(acc => ({
        k01_prefix: acc.k01_prefix || '',
        k01_number: acc.k01_number || '',
        k10_bank_code: acc.k10_bank_code || '',
        k01_iban: acc.k01_iban || '',
      }));

      if (companyAccounts.length > 0) {
        payoutAccountPrefix = companyAccounts[0].k01_prefix;
        payoutAccount = companyAccounts[0].k01_number;
        payoutAccountPost = companyAccounts[0].k10_bank_code;
        payoutAccountIBAN = companyAccounts[0].k01_iban;
      }
      state = new InitialState();
      const people = List(oCompany.people.map(person => Person({
        firstName: person.u01_name,
        lastName: person.u01_surname,
        birthDate: person.u01_birth_date,
        isDeputy: true,
      })));
      let wholeAddress = '';
      let city = '';
			let postalCode = '';
			let mainAddress = {};

      if (oCompany.addresses !== undefined && oCompany.addresses.length > 0) {
				mainAddress = oCompany.addresses[0];

        if (mainAddress.google.g50_street_name != null) {
          let streetNr = '';
          if (mainAddress.google.g01_street_number != null) {
            streetNr = mainAddress.google.g01_street_number;
          } else if (mainAddress.google.g01_premise != null) {
            streetNr = mainAddress.google.g01_premise;
          }

          wholeAddress = mainAddress.google.g50_street_name + ' ' + streetNr;
          city = mainAddress.google.g60_city_name;
          wholeAddress += ', ' + city;
        } else if (mainAddress.google.g60_city_name != null) {
          wholeAddress += mainAddress.google.g60_city_name;
          if (mainAddress.google.g01_premise != null) {
            wholeAddress += ' ' + mainAddress.google.g01_premise;
          }
          if (mainAddress.google.g30_postal_town != null) {
            wholeAddress += ', ' + mainAddress.google.g30_postal_town;
          }
        } else if (mainAddress.original.g50_street_name != null
          && mainAddress.original.g60_city_name != null
        ) {
          wholeAddress += mainAddress.original.g50_street_name;
          if (mainAddress.original.g01_street_number != null) {
            wholeAddress += ' ' + mainAddress.original.g01_street_number;
          }
          wholeAddress += ', ' + mainAddress.original.g60_city_name;
        }
      }

      return state
        .set('ic', oCompany.c01_ico)
        .set('dic', oCompany.c01_dic || '')
        .set('dic_vat', oCompany.c01_dic_vat || '')
        .set('name', oCompany.c01_name)
        .set('wholeAddress', wholeAddress)
        .set('googleAddress', getGoogleAddressFromAPI(mainAddress.google))
        .set('googleLegalAddress', getGoogleAddressFromAPI(mainAddress.google))
        .set('originalLegalAddress', mainAddress.original)
        .set('city', city || '')
        .set('postalCode', postalCode || '')
        .set('payoutAccountPrefix', payoutAccountPrefix)
        .set('payoutAccount', payoutAccount)
        .set('payoutAccountPost', payoutAccountPost)
        .set('payoutAccountIBAN', payoutAccountIBAN)
        .set('bankAccountsFromAPI', companyAccounts)
        .set('files', oCompany.files)
        .set('people', people);
    }

    case actions.CHANGE_FIELD: {
      const { name, value } = action.payload;
      if (!Array.isArray(name)) {
        if (name === 'showError' && value !== false) {
          state = state.set('showErrorClass', 'danger');
        }
        return state.set(name, value);
      }

      return state.setIn(name, value);
    }

    case actions.CHANGE_SHOP_CENTER: {
      const { name, lat, lng, manualPosition } = action.payload;
      return state
        .setIn(name.concat('lng'), lng)
        .setIn(name.concat('lat'), lat)
        .setIn(name.concat('positionChanged'), manualPosition);
    }

    case '@@router/LOCATION_CHANGE': {
      return state.set('showError', false);
    }

    case actions.SELECT_ME: {
      const { index } = action.payload;
      const selectMeEmail = state.getIn(['people', index, 'email']);

      if (selectMeEmail !== undefined && selectMeEmail.length > 0) {
        state = state.set('email', selectMeEmail);
      }

      return state.updateIn(['people'], people => List(people.map((person, indexInList) => {
        if (indexInList === index) {
          return person
            .set('selectedMe', true)
            .set('isDeputy', true);
        }

        let isDeputy = person.toJS().isDeputy;
        if (person.toJS().addedByHand) {
          isDeputy = false;
        }

        return person
          .set('selectedMe', false)
          .set('isDeputy', isDeputy);
      })));
    }

    case actions.ADD_ME: {
      let { oMe } = action.payload;
      if (oMe.selectedMe && oMe.email.length > 0) {
        state = state.set('email', oMe.email);
      }

      return state.updateIn(['people'], people => people.push(Person(oMe)));
    }

    case actions.RESET_COMPANY: {
      return new InitialState();
    }

    case actions.ADD_SHOP: {
      return state.updateIn(['shops'], shops => shops.push(Shop(action.payload.oShop)));
    }

    case actions.ADD_SHOP_FROM_FILE: {
      const { oShop } = action.payload;
      let wholeAddress = '';
      let city = '';
      if (oShop.address !== undefined) {
        if (oShop.address.google.g50_street_name != null) {
          let streetNr = '';
          if (oShop.address.google.g01_street_number != null) {
            streetNr = oShop.address.google.g01_street_number;
          } else if (oShop.address.google.g01_premise != null) {
            streetNr = oShop.address.google.g01_premise;
          }
          wholeAddress = oShop.address.google.g50_street_name + ' ' + streetNr;
          city = oShop.address.google.g60_city_name;
          wholeAddress += ', ' + city;
        } else if (oShop.address.google.g60_city_name != null) {
          wholeAddress += oShop.address.google.g60_city_name;
          if (oShop.address.google.g01_premise != null) {
            wholeAddress += ' ' + oShop.address.google.g01_premise;
          }
          if (oShop.address.google.g30_postal_town != null) {
            wholeAddress += ', ' + oShop.address.google.g30_postal_town;
          }
        } else if (oShop.address.original.g50_street_name != null && oShop.address.original.g60_city_name != null) {
          wholeAddress += oShop.address.original.g50_street_name;
          if (oShop.address.original.g01_street_number != null) {
            wholeAddress += ' ' + oShop.address.original.g01_street_number;
          }
          wholeAddress += ', ' + oShop.address.original.g60_city_name;
        }
      }
      let contactPersonId = -1;
      let addNewPerson = true;

      state.people.toArray().forEach((oPerson, index) => {
        if (oPerson.firstName === oShop.u01_name && oPerson.lastName === oShop.u01_surname) {
          contactPersonId = index;
          addNewPerson = false;
        }
      });

      if (contactPersonId === -1) {
        contactPersonId = state.get('people').size;
      }

      let shopToAdd = Shop({
        address: wholeAddress,
        bank: 1,
        c23_branch_type_id: oShop.c23_branch_type_id,
        city: oShop.address.google.g60_city_name,
        contactPersonFirstname: oShop.u01_name, // TODO: check if it is needed/used yet
        contactPersonId: contactPersonId, //state.get('people').size - if add correct user to it from file
        contactPersonLastname: oShop.u01_surname,
        editingPosition: false,
        emailForHosts: oShop.c20_email,
        googleAddress: getGoogleAddressFromAPI(oShop.address.google),
        hardCodedPosition: false,
        idCashier: '',
        lat: +oShop.address.google.g01_gps_lat,
        lng: +oShop.address.google.g01_gps_lng,
        name: oShop.c20_label,
        payoutSystem: "" + oShop.n80_cash_register_type_id,
        postalCode: oShop.address.google.g30_postal_code,
        telephoneForHosts: oShop.c20_phone,
        terminal: oShop.c20_pos,
        website: oShop.w01_url,
      });

      let stateWithAddedShop = state.updateIn(['shops'], shops => shops.push(shopToAdd));

      if (!addNewPerson) {
        return stateWithAddedShop;
      }

      return stateWithAddedShop.updateIn(['people'], people => people.push(Person({
        firstName: oShop.u01_name,
        lastName: oShop.u01_surname,
        email: oShop.u01_mail_work,
        telephone: oShop.u01_phone,
        selectedMe: false,
        addedByHand: true,
        isDeputy: false,
        onlyForShop: true,
      })));
    }

    case actions.REMOVE_ME:
      return state.deleteIn(['people', action.payload.indexToRemove]);

    case actions.ADD_DEPUTY:
      return state.updateIn(['people'], people => people.push(Person(action.payload.oDeputy)));

    case actions.REMOVE_DEPUTY:
      return state.setIn(['people', action.payload.indexRemoveDeputyToRemove, 'isDeputy'], false);

    case actions.REMOVE_SHOP:
      return state.update('shops', shops => shops.delete(action.payload.indexShopToRemove));

    default:
      return state;
  }
}

function getGoogleAddressFromAPI(apiGoogleAddress): GoogleAddressModel {
  return {
    ...apiGoogleAddress,
    g01_gps_lat: +(apiGoogleAddress && apiGoogleAddress.g01_gps_lat) || null,
    g01_gps_lng: +(apiGoogleAddress && apiGoogleAddress.g01_gps_lng) || null
  };
}
