// @flow
import 'react-select/dist/react-select.css';
import Helmet from 'react-helmet';
import SubMenu from './SubMenu.react';
import React, { Component } from 'react';
import { Link } from 'react-router';
import { changeField, addShop, removeShop, addMe, changeShopCenter, addShopFromFile, checkPOS } from '../../common/registration/actions';
import { connect } from 'react-redux';
import { getP, getLocale } from 'redux-polyglot';
import classnames from 'classnames';
import Select from 'react-select';
import { Modal, PanelGroup, Panel, Col, Row, Glyphicon } from 'react-bootstrap';
import $ from 'jquery';
import { Marker } from "react-google-maps";
import Map from '../app/components/Map';
import { toggleSpinner, loadReslists } from '../../common/app/actions';
import { scrollTopOfForm, isFocusedInput, validateTelephoneNumber, recountPeopleOptions, validateEmail } from '../browserCommonFunctions';
import { API_URL, API_VERSION, DEVELOPMENT, INSTANCE_SK } from '../browserConfig';
import GeosuggestField from '../app/components/GeosuggestField';
import type { ShopType, RegistrationState } from '../../flow/state';
import { tRouteLocale } from '../createRoutes';
import { PaymentMethods, mapToList } from './components/PaymentMethods';
import { PaymentTerminals } from './components/PaymentTerminals';
import { IconButton } from '../app/components/IconButton';
import { defaultMapCenter } from '../../common/constants';

type State = {
  shopCollapsed: { [key: number]: boolean };
  showAddShop: boolean;
  showWarningNoShop: boolean;
  showImportShops: boolean;
  showRemoveShop: boolean;
  indexShopToRemove: number;
  shopErrorIndex: number;
  showMakeMe: boolean;
  indexShopToAddPerson: number;
  showMakeMeErrors: boolean;
}

type Props = {
  registration: RegistrationState;
  posBanksList: LabelValueList;
  posSoList: LabelValueList;
  shops: ShopType[];
  location: any;
  p: any;
  pErrors: any;
  currentLocale: string;
  changeField: (arg: { name: string | string[], value: any }) => void;
  addShop: () => void;
  removeShop: () => void;
  addMe: () => void;
  toggleSpinner: () => void;
  changeShopCenter: () => void;
  addShopFromFile: () => void;
  loadReslists: () => void;
}

export class Shops extends Component<Props, State> {

  state: State = {
    shopCollapsed: {},
    showAddShop: false,
    showWarningNoShop: false,
    showImportShops: false,
    showRemoveShop: false,
    indexShopToRemove: -1,
    shopErrorIndex: 0,
    showMakeMe: false,
    indexShopToAddPerson: -1,
    showMakeMeErrors: false,
    name: '',
    address: '',
    telephone: '',
    email: '',
    website: '',
    firstName: '',
    lastName: '',
    makeMeFirstName: '',
    makeMeLastName: '',
    makeMeEmail: '',
    makeMeNameSurnameFromInput: '',
  }

  mapRef = null;
  legacyRefs: {[ key:string ]: any} = {};

  constructor(props) {
    super(props);
    this.props.loadReslists();
    window.addEventListener('keydown', this.submitOnEnter);

    if (DEVELOPMENT) {
      const data = {
        "pos_banks": {
          "3": "CS",
          "2": "CSOB",
          "1": "KB",
          "6": "RB",
          "5": "RFB",
          "4": "UB_CZECH",
          "7": "UCB"
        },
        "pos_so": {
          "7": "AEVI",
          "5": "BANIT",
          "9": "GPE",
          "10": "MONET",
          "8": "O2",
          "12": "SMP",
          "6": "SONET",
          "11": "TEST"
        },
      };
      this.props.changeField({
        name: ['pos_banks'],
        value: data.pos_banks,
      });
    
      this.props.changeField({
        name: ['pos_so'],
        value: data.pos_so,
      });

      this.props.changeField({ name: 'startedShops', value: true });
    }

    setTimeout(() => {
      if (!this.props.registration.startedShops) {
        this.props.router.push(`/`);
      }
    }, 100);
  }

  submitOnEnter = (e) => {
    if (e.keyCode === 13) { //enter = submit
      if (document.activeElement.id !== 'btn-remove-shop'
        && document.activeElement.name !== 'wholeAddress'
        && document.activeElement.name !== 'makeMeFirstName'
        && document.activeElement.name !== 'makeMeLastName'
        && document.activeElement.name !== 'makeMeEmail'
        && document.activeElement.id !== 'btn-add-person'
      ) {
        if (this.state.showWarningNoShop) {
          this.legacyRefs['continue-anyway'].click();
          return;
        }
        this.legacyRefs['submit-btn'].click();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.submitOnEnter);
  }

  checkErrors = () => {
    const { registration: { shops } } = this.props;

    return shops.some((shop, index) => {
      const isErrorThisShop = this.validateShop(shop);

      if (isErrorThisShop) {
        this.setState({ shopErrorIndex: index });
      }

      return isErrorThisShop;
    });
  }

  validateShop(shop: ShopType) {
    return shop.name.length === 0
    || shop.address.length === 0
    || !shop.lat || !shop.lng
    || !validateTelephoneNumber(shop.telephoneForHosts, true)
    || shop.telephoneForHosts.length === 0
    || shop.contactPersonId === -1
    || shop.contactPersonId === undefined
    || shop.c23_branch_type_id === undefined
    || shop.c23_branch_type_id.length === 0
    || (INSTANCE_SK && !shop.posValid);
  }

  onSubmit(e) {
    const { p, currentLocale } = this.props;
    e.preventDefault();

    if (this.checkErrors()) {
      this.props.changeField({ name: 'showError', value: true });
      this.props.changeField({ name: 'showErrorHelpers', value: true });
      this.props.changeField({ name: 'showErrorText', value: p.t('formerror') });
      scrollTopOfForm();
      this.props.registration.shops.forEach((shop, index) => {
        const isError = this.validateShop(shop);
        if (isError && this.state.shopCollapsed[index]) {
          setTimeout(() => {
            this.handleShopToggle(true, index);
          }, 1000 - 10 * index);
        }
      });
    } else if (this.props.registration.shops.size === 0) {
      this.toggle('showWarningNoShop');
    } else {
      this.props.changeField({ name: 'startedPayout', value: true });
      this.props.changeField({ name: 'showError', value: false });
      this.props.changeField({ name: 'showErrorHelpers', value: false });
      this.props.router.push(tRouteLocale('/lang/registration/payout', currentLocale));
    }
  }

  toggle = (whatToToggle) => {
    this.setState({ [whatToToggle]: !this.state[whatToToggle] });
  }

  changeStateField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  uploadFile() {
    const { currentLocale, p } = this.props;
    this.props.toggleSpinner();

    const formData = new FormData();
    formData.append('file', this.legacyRefs["importFiles"].files[0]);

    $.ajax({
      'url': API_URL + API_VERSION + '/' + currentLocale + '/registration/import/address/',
      'method': 'POST',
      'data': formData,
      'processData': false, // Do not remove, the API is not standard compliant and we need to prevent preflight requests
      'contentType': false, // Do not remove, the API is not standard compliant and we need to prevent preflight requests
      'success': (data) => {
        if (data === undefined) {
          this.props.changeField({ name: 'showError', value: true });
          this.props.changeField({ name: 'showErrorClass', value: 'danger' });
          this.props.changeField({ name: 'showErrorText', value: p.t('partnerPlacesNotLoaded') });
          this.props.toggleSpinner();
          return false;
        }
        this.props.changeField({ name: 'showError', value: true });
        this.props.changeField({ name: 'showErrorText', value: p.t('partnerPlacesLoaded') });
        this.props.changeField({ name: 'showErrorClass', value: 'success' });
        this.props.toggleSpinner();
        data.forEach((oneShop) => {
          this.props.addShopFromFile(oneShop);
        });
        this.toggle('showImportShops');
      },
      'error': () => {
        this.props.changeField({ name: 'showError', value: true });
        this.props.changeField({ name: 'showErrorText', value: p.t('partnerPlacesNotLoaded') });
        this.props.toggleSpinner();
        this.toggle('showImportShops');
      }
    });
  }

  handlePosAdd(shopIndex: number) {
    const pos = this.props.shops[shopIndex].pos || [];
    this.props.changeField({ name: ['shops', shopIndex, 'pos'], value: [ ...pos, {}] });
    this.props.changeField({ name: ['shops', shopIndex, 'posDisabled'], value: false });
  }

  handleShopToggle(expanded: boolean, index: number) {

    this.setState(state => ({
      ...state,
      shopCollapsed: {
        ...state.shopCollapsed,
        [index]: !expanded,
      }
    }));
  }

  renderShop = (shop, index) => {
    const { p, registration: { showErrorHelpers, cash_registers, affil_types, people } } = this.props;
    const oLangLng = (shop.lat && shop.lng) ? { lat: shop.lat, lng: shop.lng } : null;

    return (
      <Panel key={"shoplink-" + index} bsStyle="primary" className="expand-xs-compact" onToggle={exp => this.handleShopToggle(exp, index)} expanded={!this.state.shopCollapsed[index]}>
        <Panel.Heading>
          <Panel.Title id={"shoplink-" + index} toggle>
            <Row>
              <Col xs={10}>
                <h3 className="panel-title">
                  {shop.name.length > 0 ? shop.name : p.t('shopdefaultname')}
                  <br className="visible-xs" />
                  <span className="hidden-xs offset-left offset-right">|</span>
                  <span className="small">{shop.address}</span>
                </h3>
              </Col>
              <Col xs={2} className="text-right">
                <span className="glyphicon glyphicon-menu-down" aria-hidden="true" />
              </Col>
            </Row>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body id={"salepoint" + index} collapsible>
          <div className="text-right">
            <button
              type="button"
              onClick={() => {
                this.toggle('showRemoveShop');
                this.setState({ indexShopToRemove: index });
                setTimeout(() => { this.legacyRefs['btn-remove-shop'].focus(); }, 150);
              }}
              className="btn btn-link btn-link-inline btn-link-danger"
            >
              <span className="hidden-xs offset-right">{p.t('delete')}</span>
              <span className="btn btn-danger btn-lg btn-circle">
                <Glyphicon glyph="trash" className="icon-shift-down" />
              </span>
            </button>
          </div>

          <Row>
            <Col sm={6} md={8} className="offset-bottom-xs-max">
              <GeosuggestField
                name="wholeAddress"
                id={`geoshop-${index}`}
                label={p.t('address')}
                initialValue={shop.address}
                inputClassName="form-control js-form-control"
                onSuggestSelect={(e) => {
                  this.props.changeField({
                    name: ['shops', index, 'address'],
                    value: e ? e.label : '',
                  });
                  this.props.changeField({
                    name: ['shops', index, 'googlePlaceId'],
                    value: e ? e.placeId : null,
                  });
                  this.props.changeShopCenter({
                    name: ['shops', index],
                    lat: e ? e.location.lat : null,
                    lng: e ? e.location.lng : null,
                  });
                }}
                showError={((shop.address.length === 0 || !shop.googlePlaceId) && showErrorHelpers) && p.t('choosevalidaddress')}
              />
              <div ref={r => this.legacyRefs[`mapElementForWidth${index}`] = r} className="map-small map-bordered map-with-tools">
                <div className="map-tools">{this.renderMapButton(shop, index)}</div>
                {!this.state.shopCollapsed[index] && <Map
                  containerElement={<div style={{ width: this.state.mapWidth, height: this.state.mapHeight }} />}
                  defaultDraggable={false}
                  editingPosition={shop.editingPosition}
                  mapRef={ref => this.mapRef = ref}
                  center={oLangLng || defaultMapCenter}
                  onCenterChanged={() => {
                    if (shop.editingPosition) {
                      const center = this.mapRef.getCenter();
                      const lat = center.lat();
                      const lng = center.lng();
                      this.props.changeShopCenter({ name: ['shops', index], lat, lng, manualPosition: true });
                    }
                  }}
                >
                  {(shop.lat && shop.lat !== defaultMapCenter.lat && shop.lng && shop.lng !== defaultMapCenter.lng)
                    && <Marker position={{
                      lat: shop.lat || shop.googleAddress.g01_gps_lat,
                      lng: shop.lng || shop.googleAddress.g01_gps_lng,
                    }} />
                  }
                </Map>}
              </div>
            </Col>

            <Col sm={6} md={4}>
              <div className={classnames("form-group", { 'has-error': (shop.name.length === 0 && showErrorHelpers) })}>
                <label htmlFor={"name-" + index} className={classnames("control-label-floating", { active: shop.name.length > 0 || isFocusedInput('name-' + index, 'id') })}>
                  {p.t('name')}
                </label>
                <input
                  onChange={(e) => {
                    this.props.changeField({
                      name: ['shops', index, e.target.name],
                      value: e.target.value
                    });
                  }}
                  name="name"
                  value={shop.name}
                  onFocus={() => { this.forceUpdate(); }}
                  onBlur={() => { this.forceUpdate(); }}
                  id={`name-${index}`}
                  type="text"
                  className="form-control js-form-control"
                />
                {(shop.name.length === 0 && showErrorHelpers)
                  && <div className="help-block">{p.t('entervalidshopname')}</div>
                }
              </div>

              <div className={classnames("form-group", { "has-error": (shop.c23_branch_type_id.length === 0 && showErrorHelpers) })}>
                <label className="control-label-floating active">{p.t('shop_type')}</label>
                <Select
                  className="Select-form"
                  resetValue=""
                  options={mapToList(affil_types)}
                  placeholder={p.t('select')}
                  value={shop.c23_branch_type_id}
                  onChange={(value) => {
                    this.props.changeField({
                      name: ['shops', index, 'c23_branch_type_id'],
                      value: value.value
                    });
                  }}
                  id={`salepoint[${index}][c23_branch_type_id]`} />
                {(shop.c23_branch_type_id.length === 0 && showErrorHelpers)
                  && <div className="help-block">{p.t('validShopType')}</div>
                }
              </div>

              <div className={classnames("form-group", { "has-error": (!validateTelephoneNumber(shop.telephoneForHosts, true) && showErrorHelpers) })}>
                <label
                  htmlFor={`telephoneForHosts-${index}`}
                  className={classnames("control-label-floating", { active: ("" + shop.telephoneForHosts).length > 0 || isFocusedInput('telephoneForHosts-' + index, 'id') })}
                >
                  {p.t('guesttelephone')}
                </label>
                <input
                  onChange={(e) => {
                    this.props.changeField({ name: ['shops', index, e.target.name], value: e.target.value });
                  }}
                  name="telephoneForHosts"
                  value={shop.telephoneForHosts}
                  onFocus={() => { this.forceUpdate(); }}
                  onBlur={() => { this.forceUpdate(); }}
                  id={`telephoneForHosts-${index}`}
                  ref={r => this.legacyRefs[`telephoneForHosts-${index}`] = r}
                  type="tel"
                  className="form-control js-form-control"
                />
                {(!validateTelephoneNumber(shop.telephoneForHosts, true) && showErrorHelpers)
                  && <div className="help-block">{p.t('validtelephone')}</div>
                }
              </div>

              <div className={classnames("form-group")}>
                <label
                  htmlFor={`emailForHosts-${index}`}
                  className={classnames('control-label-floating', { active: shop.emailForHosts.length > 0 || isFocusedInput(`emailForHosts-${index}`, 'id') })}
                >
                  {p.t('guestemail')}
                </label>
                <input
                  onChange={(e) => {
                    this.props.changeField({
                      name: ['shops', index, e.target.name],
                      value: e.target.value
                    });
                  }}
                  name="emailForHosts"
                  value={shop.emailForHosts}
                  onFocus={() => { this.forceUpdate(); }}
                  onBlur={() => { this.forceUpdate(); }}
                  id={`emailForHosts-${index}`}
                  type="email"
                  className="form-control js-form-control"
                />
              </div>

              <div className={classnames("form-group")}>
                <label
                  htmlFor={"website-" + index}
                  className={classnames("control-label-floating", { active: shop.website.length > 0 || isFocusedInput('website-' + index, 'id') })}
                >
                  {p.t('web')}
                </label>
                <input
                  onChange={(e) => {
                    this.props.changeField({
                      name: ['shops', index, e.target.name],
                      value: e.target.value
                    });
                  }}
                  name="website"
                  value={shop.website}
                  onFocus={() => { this.forceUpdate(); }}
                  onBlur={() => { this.forceUpdate(); }}
                  id={"website-" + index}
                  type="text"
                  className="form-control js-form-control"
                />
              </div>
              <Row>
                <Col sm={12}>
                  <div
                    className={classnames("form-group", {
                      "has-error": (+shop.contactPersonId === -1 || shop.contactPersonId === undefined) && showErrorHelpers
                    })}
                  >
                    <label className="control-label-floating active">{p.t('contactperson')}</label>
                    <Select
                      className="Select-form"
                      resetValue="-1"
                      filterOption={(option, filterString) => option.label.includes(filterString) || option.value === 'new'}
                      options={recountPeopleOptions(people)}
                      onInputChange={(e) => {
                        this.setState({ makeMeNameSurnameFromInput: e });
                      }}
                      optionRenderer={e => (<div>
                        {e.value === 'new'
                          ? this.state.makeMeNameSurnameFromInput + ' zadat nové'
                          : e.label
                        }
                      </div>)}
                      placeholder={p.t('select')}
                      value={shop.contactPersonId}
                      onChange={(value) => {
                        if (value.value === 'new') {
                          this.setState({
                            makeMeFirstName: this.state.makeMeNameSurnameFromInput.split(' ')[0],
                            makeMeLastName: this.state.makeMeNameSurnameFromInput
                              .split(' ')
                              .filter((oName, index) => index !== 0)
                              .join(' '),
                            indexShopToAddPerson: index,
                          });
                          this.toggle('showMakeMe');
                          setTimeout(() => { this.legacyRefs["addPerson"].focus(); }, 150);
                        } else {
                          this.props.changeField({
                            name: ['shops', index, 'contactPersonId'],
                            value: value.value
                          });
                        }
                      }}
                      id={"contact-person-id"}
                    />

                    {((+shop.contactPersonId === -1 || shop.contactPersonId === undefined) && showErrorHelpers)
                      && <div className="help-block">{p.t('selectcontact')}</div>
                    }
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="page-header header-wrapper">
            <h3>{INSTANCE_SK ? p.t('paymentterminals') : p.t('paymentmethods')}</h3>
            {INSTANCE_SK && <IconButton label={p.t('paymentterminaladd')} icon="plus" onClick={() => this.handlePosAdd(index)} />}
          </div>
          {INSTANCE_SK ?
            <PaymentTerminals
              p={p}
              posBanksList={this.props.posBanksList}
              posSoList={this.props.posSoList}
              rows={(shop.pos && shop.pos.length > 0) ? shop.pos : [{}]}
              terminalDisabled={shop.posDisabled}
              showErrorHelpers={showErrorHelpers}
              onChange={(rows, posDisabled) => {
                this.props.changeField({
                  name: ['shops', index, 'pos'],
                  value: rows
                });
                this.props.changeField({
                  name: ['shops', index, 'posDisabled'],
                  value: posDisabled
                });
              }}
              checkPOS={(posIndex) => this.props.checkPOS(index, posIndex)}
              onValid={valid => this.props.changeField({ name: ['shops', index, 'posValid'], value: valid })}
            /> :
            <PaymentMethods
              p={p}
              terminal={shop.terminal}
              cash_registers={cash_registers}
              payoutSystem={shop.payoutSystem}
              index={index}
              onTerminalChange={(val) => {
                this.props.changeField({
                  name: ['shops', index, 'terminal'],
                  value: val
                });
              }}
              onPayoutSystemChange={(value) => {
                this.props.changeField({
                  name: ['shops', index, 'payoutSystem'],
                  value: value
                });
              }}
            />}
        </Panel.Body>
      </Panel>);
  }

  renderMapButton = (shop, index) => {
    const { p } = this.props;
    if (!shop.editingPosition) {
      return (<button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() => {
          this.props.changeField({
            name: ['shops', index, 'editingPosition'],
            value: true
          });
        }}
      >
        {p.t('correctposition')}
      </button>);
    }

    return (<button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={() => {
        this.props.changeField({
          name: ['shops', index, 'editingPosition'],
          value: false
        });
        this.props.changeField({
          name: ['shops', index, 'hardCodedPosition'],
          value: true
        });
      }}
    >
      {p.t('apply')}
    </button>);
  };

  render() {
    const { location, registration, p, currentLocale, shops } = this.props;

    let basicdatapath = tRouteLocale('/lang/registration/basic-data', currentLocale);

    let importTemplateUrl = '';
    let importFile = registration.files.filter(oneFile => +oneFile.f04_code === 6);
    if (importFile.length > 0) {
      importTemplateUrl = importFile[0].link;
    }

    return (
      <main id="content" role="main" className="site-content bg-gray-lighter">
        <Helmet title={p.t('shopsmeta')} />
        <div className="section-content">
          <div className="container">

            <SubMenu location={location} />
            <hr className="offset-top-none offset-bottom-large-xs-max offset-bottom-large" />
            <div id="import" className={classnames('collapse', this.state.showImportShops && 'in')}>
              <div className="well well-light well-raised offset-bottom-large">
                <button
                  type="button"
                  className="close pull-right offset-left-large"
                  aria-label="Zavřít"
                  aria-expanded="true"
                  aria-controls="import"
                  onClick={() => { this.toggle('showImportShops'); }}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h2>{p.t('importexcel')}</h2>
                <div className="row offset-bottom">
                  <div className="col-md-6 offset-bottom-large-sm-max">
                    <h3>1. {p.t('filltable')}</h3>
                    <p>
                      {p.t('filltablemessage')}
                    </p>
                    <a href={importTemplateUrl} type="button" className="btn btn-default">
                      <span className="glyphicon glyphicon-save icon-shift-down offset-right" />
                      {p.t('samplexls')}
                    </a>
                  </div>
                  <div className="col-md-6">
                    <h3>2. {p.t('uploadfile')}</h3>
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      this.uploadFile();
                    }}>
                      <div className="form-group">
                        <label htmlFor="importFile">{p.t('importmessage')}</label>
                        <input type="file" id="importFile"
                          ref={r => this.legacyRefs["importFiles"] = r} />
                      </div>
                      <button type="submit" className="btn btn-primary" >
                        <span className="glyphicon glyphicon-import icon-shift-down offset-right" />
                        {p.t('import')}
                      </button>
                    </form>
                  </div>
                </div>{/* .row */}
              </div>
            </div>
            <button
              onClick={() => { this.toggle('showImportShops'); }}
              type="button"
              className="btn btn-link btn-link-inline pull-right offset-left-large"
              aria-expanded="false"
              aria-controls="import"
            >
              <span className="hidden-xs offset-right">{p.t('import')}</span>
              <span className="btn btn-info btn-lg btn-circle">
                <span className="glyphicon glyphicon-import icon-shift-down" />
              </span>
            </button>
            <h2 className="offset-top-none offset-bottom-large">{p.t('companyrepresentatives')}</h2>
            <PanelGroup
              accordion
              id="shops-panel"
            >
            {shops.map(this.renderShop)}
            </PanelGroup>
            <div className="text-center offset-bottom-large">
              <button
                type="button"
                ref={r => this.legacyRefs["add-shop-shops"] = r}
                className="btn btn-default btn-block btn-lg"
                onClick={() => { this.props.addShop({}); }}
              >
                <span className="btn-icon-left">
                  <span className="glyphicon glyphicon-plus" aria-hidden="true" /> {p.t('addpartner')}
                </span>
              </button>
            </div>

            <form onSubmit={this.onSubmit.bind(this)}>
              {/* Navigation */}
              <div className="row">
                <div className="col-sm-5 col-sm-push-7 col-md-4 col-md-push-8 offset-bottom-xs-max">
                  <button type="submit" ref={r => this.legacyRefs["submit-btn"] = r} className="btn btn-accent btn-block btn-lg">
                    <span className="btn-icon-right">
                      {p.t('setuppayout')} <span className="glyphicon glyphicon-menu-right" aria-hidden="true" />
                    </span>
                  </button>
                </div>
                <div className="col-sm-5 col-sm-pull-5 col-md-4 col-md-pull-4">
                  <Link to={basicdatapath} className="btn btn-accent btn-accent-outline btn-block btn-lg btn-icon-left">
                    <span className="glyphicon glyphicon-menu-left" aria-hidden="true" />
                    {p.t('previous')}
                  </Link>
                </div>
              </div>{/* .row */}
            </form>
          </div>
        </div>

        <Modal show={this.state.showRemoveShop} onHide={this.toggle.bind(this, 'showRemoveShop')}>
          <Modal.Body>{p.t('deletethisshop')}</Modal.Body>
          <Modal.Footer>
            <button
              ref={r => this.legacyRefs["btn-remove-shop"] = r}
              id="btn-remove-shop"
              className="btn btn-primary pull-right offset-left"
              onClick={() => {
                this.props.removeShop(this.state.indexShopToRemove);
                this.toggle('showRemoveShop');
              }}
            >
              {p.t('delete')}
            </button>
            <button className="btn btn-default" onClick={this.toggle.bind(this, 'showRemoveShop')}>{p.t('close')}</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showWarningNoShop} onHide={this.toggle.bind(this, 'showWarningNoShop')}>
          <Modal.Body>{p.t('withoutpartnerplace')}</Modal.Body>
          <Modal.Footer>
            <button ref={r => this.legacyRefs["continue-anyway"] = r} className="btn btn-primary pull-right offset-left" onClick={() => {
              this.toggle('showWarningNoShop');
              this.props.changeField({ name: 'startedPayout', value: true });
              this.props.router.push(tRouteLocale('/lang/registration/payout', currentLocale));
            }}>
              {p.t('continue')}
            </button>
            <button className="btn btn-default" onClick={this.toggle.bind(this, 'showWarningNoShop')}>{p.t('close')}</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showMakeMe} onHide={() => this.toggle('showMakeMe')}>
          <form onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (this.state.makeMeFirstName.length === 0
              || this.state.makeMeLastName.length === 0
              || (this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail))
            ) {
              this.setState({ showMakeMeErrors: true });
              return false;
            }
            this.setState({ 'showMakeMeErrors': false });
            this.toggle('showMakeMe');

            this.props.addMe({
              firstName: this.state.makeMeFirstName,
              lastName: this.state.makeMeLastName,
              email: this.state.makeMeEmail,
              selectedMe: false,
              addedByHand: true,
              isDeputy: false,
              onlyForShop: true,
            });

            this.setState({
              makeMeFirstName: '',
              makeMeLastName: '',
              makeMeEmail: '',
              errors: [],
              nameSurname: '',
              makeMeNameSurname: '',
            });

            this.props.changeField({
              name: ['shops', this.state.indexShopToAddPerson, 'contactPersonId'],
              value: this.props.registration.toJS().people.length
            });

            return false;
          }}>
            <Modal.Header closeButton>{p.t('addperson')}</Modal.Header>
            <Modal.Body>
              <div className={classnames("form-group", { "has-error": (this.state.showMakeMeErrors && this.state.makeMeFirstName.length === 0) })}>
                <label
                  htmlFor="makeMeFirstName"
                  className={classnames("control-label-floating", { active: this.state.makeMeFirstName.length > 0 || isFocusedInput('makeMeFirstName') })}
                >
                  {p.t('firstname')}
                </label>
                <input
                  value={this.state.makeMeFirstName}
                  onChange={this.changeStateField}
                  name="makeMeFirstName"
                  onFocus={() => { this.forceUpdate(); }}
                  onBlur={() => { this.forceUpdate(); }}
                  type="text"
                  id="makeMeFirstName"
                  className="form-control js-form-control"
                />
                {(this.state.showMakeMeErrors && this.state.makeMeFirstName.length === 0)
                  && <div className="help-block">{p.t('validfirstname')}</div>
                }
              </div>

              <div className={classnames("form-group", { 'has-error': (this.state.showMakeMeErrors && this.state.makeMeLastName.length === 0) })}>
                <label
                  htmlFor="makeMeLastName"
                  className={classnames("control-label-floating", { active: this.state.makeMeLastName.length > 0 || isFocusedInput('makeMeLastName') })}
                >
                  {p.t('surname')}
                </label>
                <input value={this.state.makeMeLastName} onChange={this.changeStateField} name="makeMeLastName"
                  onFocus={() => { this.forceUpdate(); }} onBlur={() => { this.forceUpdate(); }}
                  type="text" id="makeMeLastName" className="form-control js-form-control"
                />
                {(this.state.showMakeMeErrors && this.state.makeMeLastName.length === 0)
                  && <div className="help-block">{p.t('validlastname')}</div>
                }
              </div>
              <div className={classnames("form-group", { "has-error": (this.state.showMakeMeErrors && (this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail))) })}>
                <label htmlFor="makeMeEmail" className={classnames("control-label-floating", { active: this.state.makeMeEmail.length > 0 || isFocusedInput('makeMeEmail') })}>
                  {p.t('email')}
                </label>
                <input
                  value={this.state.makeMeEmail}
                  onChange={this.changeStateField}
                  name="makeMeEmail"
                  onFocus={() => { this.forceUpdate(); }}
                  onBlur={() => { this.forceUpdate(); }}
                  type="text"
                  id="makeMeEmail"
                  className="form-control js-form-control"
                />
                {(this.state.showMakeMeErrors && (this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail)))
                  && <div className="help-block">{p.t('inputvalidemail')}</div>
                }
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button className="btn btn-primary pull-right offset-left" ref={r => this.legacyRefs["addPerson"] = r}  id="btn-add-person">{p.t('add')}</button>
              <button
                className="btn btn-default "
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle('showMakeMe');
                }}
              >
                {p.t('cancel')}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </main>)
  }
}

export default connect((state: RootState) => ({
  registration: state.registration,
  shops: state.registration.shops.toJS(),
  posBanksList: mapToList(state.registration.pos_banks),
  posSoList: mapToList(state.registration.pos_so),
  p: getP(state, { polyglotScope: 'registration' }),
  pErrors: getP(state, { polyglotScope: 'errors' }),
  currentLocale: getLocale(state),
}),
  { changeField, addShop, removeShop, addMe, toggleSpinner, changeShopCenter, addShopFromFile, loadReslists, checkPOS }
)(Shops);
