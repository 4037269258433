import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import favicon from '../../common/app/favicon';
import { toggleSpinner, loadReslists } from '../../common/app/actions';
import { connect } from 'react-redux';
import { locationShape } from 'react-router';
import Spinner from 'react-spinner';
import { setLanguage, getLocale } from 'redux-polyglot';
import { DEFAULT_LANG } from '../browserConfig';
import messages from '../messages';


// v4-alpha.getbootstrap.com/getting-started/introduction/#starter-template
const bootstrap4Metas = [
  { charset: 'utf-8' },
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
  },
  {
    'http-equiv': 'x-ua-compatible',
    content: 'ie=edge',
  },
];

class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    currentLocale: PropTypes.string,
    location: locationShape,
  };

  componentWillMount() {
    this.updateLocale(this.props.location.pathname, this.props.currentLocale);
  }

  componentWillReceiveProps(nextProps) {
    this.updateLocale(nextProps.location.pathname, nextProps.currentLocale);
  }

  componentDidMount() {
    // if (ENVIRONMENT !== 'develop') {
    // }
  }

  updateLocale(pathname: string, currentLocale?: string) {
    const parts = pathname.match(/^\/(en|sk).*$/);
    let locale = parts && parts.length > 1 && parts[1];

    if (!currentLocale) {
      currentLocale = this.props.currentLocale;
    }

    if (!locale) {
      locale = DEFAULT_LANG;
    }

    if(locale !== currentLocale) {
      this.props.setLanguage(locale, messages[locale]);
      this.props.loadReslists();
    }
  }

  render() {
    const { children, currentLocale, showSpinner } = this.props;
    // comment just for rebuild
    return (
      <div className="site">
        <Helmet
          htmlAttributes={{ lang: currentLocale }}
          titleTemplate="%s — GUSTO KARTA"
          title="Všechna jídla. Jedna karta."
          meta={[
            ...bootstrap4Metas,
            {
              name: 'description',
              content: 'Gusto Karta je nástroj pro snadné obstarání jídla, pro rychlou platbu. Je určena firemním zákazníkům jako náhrada za papírové stravenky, ale také soukromým osobám.',
            },
            ...favicon.meta,
          ]}
          link={[
            ...favicon.link,
            {
              'rel': 'stylesheet',
              'href': 'https://fonts.googleapis.com/css?family=Palanquin:300,400,700&subset=latin-ext',
            }
          ]}
        />
        {/* Pass location to ensure header active links are updated. */}
        {children}
        {showSpinner && <div className="spinner"><Spinner /></div>}
      </div>
    );
  }
}

export default connect(state => ({
  currentLocale: getLocale(state),
  showSpinner: state.app.showSpinner,
}), { toggleSpinner, setLanguage, loadReslists })(App);
