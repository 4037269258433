import Helmet from 'react-helmet';
import React, { Component } from 'react';
import { Link } from 'react-router';
import SubMenu from './SubMenu.react';
import { changeField, addDeputy, removeDeputy, sendRegistration } from '../../common/registration/actions';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { getP, getLocale } from 'redux-polyglot';
import classnames from 'classnames';
import { scrollTopOfForm, validateEmail, isFocusedInput, recountPeopleOptions, formatAccount } from '../browserCommonFunctions';
import { INSTANCE_SK } from '../browserConfig';
import { toggleSpinner } from '../../common/app/actions';
import Select from 'react-select';
import ShopsPanel from '../app/components/ShopsPanel';
import { tRouteLocale } from '../createRoutes';
import { phonePrefixDefault } from '../../common/constants';
import { selectSelectedAccount, selectDiscountSettingEnabled } from '../../common/registration/selectors';
import CheckboxField from '../app/components/CheckboxField';

export class Recapitulation extends Component {

  mapRef = null;
  legacyRefs: {[ key:string ]: any} = {};

  constructor() {
    super();
    this.state = {
      showOnlyOneDeputy: false,
      showAddDeputy: false,
      showMakeMe: false,
      showRemoveDeputy: false,
      showRemoveFullPower: false,
      firstName: '',
      makeMeFirstName: '',
      makeMeLastName: '',
      makeMeEmail: '',
      lastName: '',
      email: '',
      telephone: '',
      indexRemoveDeputyToRemove: -1,
      nameSurname: '',
      makeMeNameSurnameFromInput: '',
      showMakeMeErrors: false,
      confirmTerms: false,
    };

    this.toggleShowAddDeputy = this.toggleShowAddDeputy.bind(this);
    this.changeStateField = this.changeStateField.bind(this);
    this.toggle = this.toggle.bind(this);
    this.reposition = this.reposition.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.props.registration.startedRecapitulation) {
        this.props.router.push(`/`);
      }
    }, 100);
  }

  toggle(whatToToggle) {
    this.setState({ [whatToToggle]: !this.state[whatToToggle] });
  }

  toggleShowAddDeputy() {
    this.setState({ showAddDeputy: !this.state.showAddDeputy });
  }

  changeStateField(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  reposition(index) {
    this.props.changeField({ name: ['shops', index, 'lat'], value: 49.888 });
    this.props.changeField({ name: ['shops', index, 'lng'], value: 17.666 });
  }

  async onSubmit(e) {
    e.preventDefault();

    const { registration, p, currentLocale } = this.props;

    const selectedIdPerson = registration.people.findIndex(person => person.selectedMe);
    let theBankAccount;
    if (registration.bankAccountsFromAPI.length === 0 || +registration.selectedBankAccountIndex === 0) {
      theBankAccount = {
        "k01_prefix": registration.payoutAccountPrefix,
        "k01_number": registration.payoutAccount,
        "k10_bank_code": registration.payoutAccountPost,
        "k01_iban": registration.payoutAccountIBAN,
      };
    } else {
      theBankAccount = registration.bankAccountsFromAPI[registration.selectedBankAccountIndex];
    }

    let otherResponsibles = [];
    registration.toJS().people
      .filter(person => (!person.onlyDeputy || (person.onlyDeputy && person.isDeputy)))
      .forEach((person, index) => {
        if (person.isDeputy) {
          otherResponsibles = otherResponsibles.concat(index);
        }
      });

    const deputies = registration.people.filter(person => person.isDeputy);
    if (deputies.length === 0) {
      this.props.changeField({ name: 'showError', value: true });
      this.props.changeField({ name: 'showErrorHelpers', value: true });
      this.props.changeField({ name: 'showErrorText', value: p.t('selectrepresentatives') });
      scrollTopOfForm();
      return false;
    } else { // am i the only one and not statutary ->alert
      const isNotOnlyStatutar = deputies.some(person => !person.addedByHand);
      if (!isNotOnlyStatutar && !this.state.showOnlyOneDeputy && !registration.fullPower) {
        this.toggle('showOnlyOneDeputy');
        return false;
      }
    }

    let legalAddress = registration.googleLegalAddress;
    if (legalAddress.g01_gps_lat === null && legalAddress.g01_gps_lng === null && legalAddress.g90_country_name === null) {
      legalAddress = registration.originalLegalAddress;
    }

    const oToSend = {
      "c01_name": registration.name,
      "c01_ico": registration.ic,
      "c01_dic": registration.dic,
      "c01_dic_vat": registration.dic_vat,
      "c01_mail": registration.email,
      "c01_phone": (registration.telephone.length > 0 && registration.telephone !== phonePrefixDefault) ? registration.telephone : '',
      "main_user_id": selectedIdPerson,
      "other_responsibles": otherResponsibles,
      "power_of_attorney": {
        file_name: registration.fullPowerName,
        data: registration.fullPowerData,
      },
      "payment_frequency": registration.payoutType,
      "c30_dealer_code": registration.agentureCode,
      "legal_address": legalAddress,
      "people": registration.toJS().people
        .filter(person => !person.onlyDeputy || (person.onlyDeputy && person.isDeputy))
        .map((person, index) => ({
          "id": index,
          "u01_name": person.firstName,
          "u01_surname": person.lastName,
          "u01_birth_date": person.birthDate,
          "u01_mail_work": person.selectedMe ? registration.email : person.email,
          "u01_phone": person.telephone,
        })),
      "affiliates": registration.shops.toJS().map(shop => ({
        "c20_label": shop.name,
        "c20_phone": shop.telephoneForHosts,
        "c20_email": shop.emailForHosts,
        "c20_pos": shop.terminal ? 1 : 0,
        "c23_branch_type_id": shop.c23_branch_type_id,
        "w01_url": shop.website,
        "responsible_user_id": shop.contactPersonId,
        "c26_user_addr_role_name": "",//?
        "c01_post_terminal": shop.terminal,
        "c10_cash_register_name": shop.payoutSystem,
        "n80_cash_register_type_id": shop.payoutSystem,
        // add address_id from BE, if we have one
        "c20_company_address_id": (shop.googleAddress && shop.googleAddress.g01_address_id) || undefined,
        "address": {
          "google": { "g01_google_place_id": shop.googlePlaceId },
          "c20_gps_lat": shop.positionChanged ? shop.lat : undefined,
          "c20_gps_lng": shop.positionChanged ? shop.lng : undefined,
        },
        "pos": INSTANCE_SK ? shop.pos : undefined,
      })),
      "bank_accounts": [theBankAccount],
			"product_code": registration.product_code,
			"rekreo_product": !!registration.rekreo_product,
      "sale_percent": registration.sale_percent,
      "r13_email": registration.r13_email,
    };

    return this.props.sendRegistration(oToSend).then(succ => {
      if(!succ) {
        this.setState({ showOnlyOneDeputy: false });
      } else {
        this.props.router.push(tRouteLocale('/lang/registration/posted', currentLocale));
      }

      return succ;
    });
  }

  addFile(e) {
    let selectedFile = document.getElementById("addFile").files;
    if (selectedFile.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent) => {
        this.props.changeField({ name: 'fullPowerData', value: fileLoadedEvent.target.result });
      };
      fileReader.readAsDataURL(selectedFile[0]);
    }
    this.props.changeField({ name: 'fullPower', value: true });
    this.props.changeField({ name: 'fullPowerName', value: e.target.files[0].name });
  }

  render() {
    const { registration, location, p, currentLocale, selectedAccount, discountSettingEnabled } = this.props;
    const showErrorHelpers = registration.showErrorHelpers;
    const basicdatapath = tRouteLocale('/lang/registration/basic-data/', currentLocale);
    const shoppath = tRouteLocale('/lang/registration/shops/', currentLocale);
    const payoutpath = tRouteLocale('/lang/registration/payout', currentLocale);

		const contactPerson = registration.people.filter(person => person.get('selectedMe')).toJS()[0] || {};
		const payoutFreq = registration.payment_frequency.find(p => p.id === registration.payoutType);

		const sale_percent = discountSettingEnabled && registration.sale_percent ? registration.sale_percent : false;

    return (
      <main id="content" role="main" className="site-content bg-gray-lighter">
        <Helmet title={p.t('metarecapitulation')} />
        <div className="section-content">
          <div className="container">
            <SubMenu location={location} startedRecapitulation={registration.startedRecapitulation} />
            <hr className="offset-top-none offset-bottom-large-xs-max offset-bottom-large" /> {/* Basic Information */}
            <Link to={basicdatapath} className="link-plain pull-right">
              <span className="offset-right hidden-xs">{p.t('modify')}</span>
              <span className="btn btn-info btn-circle btn-lg">
                <span className="glyphicon glyphicon-pencil" />
              </span>
            </Link>

            <h2 className="offset-top-none">{p.t('company')}</h2>
            <form onSubmit={this.onSubmit.bind(this)}>
              <div className="row">
                <div className="col-sm-6 offset-bottom-large-xs-max">
                  <h3>{p.t('invoicedata')}</h3>
                  <p>
                    <strong>{registration.name}</strong><br /> {registration.wholeAddress}<br /> {registration.city}<br /> {registration.postalCode}
                  </p>
                  <p>
                    {p.t('ic')}: {registration.ic}<br />
                    {p.t('dic')}: {registration.dic}
                    {INSTANCE_SK && <br />}
                    {INSTANCE_SK && <span>
                      {p.t('dic_vat')}: {registration.dic_vat}
                    </span>}
                  </p>
                </div>
                <div className="col-sm-6 offset-bottom-large-xs-max">
                  <h3>{p.t('contactperson')}</h3>
                  <p>
                    <strong>{contactPerson.firstName} {contactPerson.lastName}</strong><br />
                    <a href={"mailto:" + registration.email} className="link">{registration.email}</a><br /> {(registration.telephone.length > 0 && registration.telephone !== phonePrefixDefault) && <a href={"tel:" + registration.telephone} className="link text-nowrap">{registration.telephone}</a>}
                  </p>
                </div>
              </div>{/* .row */}
              <hr className="offset-top-large offset-bottom-large" /> {/* Points of Sale */}
              <Link to={shoppath} className="link-plain pull-right">
                <span className="offset-right hidden-xs">{p.t('modify')}</span>
                <span className="btn btn-info btn-circle btn-lg">
                  <span className="glyphicon glyphicon-pencil" />
                </span>
              </Link>

              <h2 id="points-of-sale" className="offset-top-none offset-bottom-large">{p.t('shops')}</h2>
              <ShopsPanel
                shops={registration.shops.toJS()}
                affil_types={registration.affil_types}
                people={registration.people.toJS()}
                cash_registers={registration.cash_registers}
                p={p}
              />
              <hr className="offset-top-large offset-bottom-large" /> {/* Payout */}
              <Link to={payoutpath} className="link-plain pull-right">
                <span className="offset-right hidden-xs">{p.t('modify')}</span>
                <span className="btn btn-info btn-circle btn-lg">
                  <span className="glyphicon glyphicon-pencil" />
                </span>
              </Link>

              <h2 className="offset-top-none offset-bottom-large">{p.t('payouts')}</h2>
              <div className="row text-left-xs text-center">
                {payoutFreq && <div className="col-sm-4 offset-bottom-xs-max">
                  {payoutFreq.frequency}
                  {' '}
                  {payoutFreq.unit}
                </div>}
                {payoutFreq && <div className="col-sm-4 offset-bottom-xs-max">
                  {sale_percent ?
                    p.t('commissiondiscountrecap', { sale_percent, provision: payoutFreq.provision}) :
                    `${payoutFreq.provision} % ${p.t('commission')}`}
                </div>}
                  <div className="col-sm-4">
                    {p.t('toaccount')}:
                  <br className="visible-sm" />
                    {' '}{formatAccount(selectedAccount)}
                  </div>
              </div>
              <hr className="offset-top-large offset-bottom-large" /> {/* Contract */}

              <h2 className="offset-top-none offset-bottom-large">{p.t('companyrepresented')}</h2>
              <div className="row offset-bottom-large">
                {registration.people.map((deputyPerson, indexPerson) => (
                  <div
                    key={deputyPerson.firstName + deputyPerson.lastName + indexPerson}
                    className={classnames("col-sm-6 col-md-4 offset-bottom", { hidden: !deputyPerson.isDeputy })}
                  >
                    <div className="btn btn-default btn-block btn-xl btn-icon-right active">
                      <div>
                        <h3 className="h2 offset-bottom-none" style={(!deputyPerson.selectedMe && !((!deputyPerson.selectMe && deputyPerson.email.length > 0) && deputyPerson.email)) ? { marginTop: 35 } : {}}>
                          {deputyPerson.firstName} {deputyPerson.lastName}
                        </h3>
                        <p className="small">
                          {deputyPerson.selectedMe && registration.email}
                          {(!deputyPerson.selectedMe && deputyPerson.email.length > 0) && deputyPerson.email}
                        </p>
                      </div>
                      <button
                        type="button"
                        className="btn-link btn-link-inline link-unstyled"
                        onClick={() => {
                          this.setState({ indexRemoveDeputyToRemove: indexPerson });
                          this.toggle('showRemoveDeputy');
                          setTimeout(() => { this.legacyRefs['btn-remove-person-recapitulation'].focus(); }, 100);
                        }}
                      >
                        <span className="glyphicon glyphicon-trash" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                ))}

                <div className="col-sm-6 col-md-4 offset-bottom text-center">
                  <button
                    type="button"
                    className="btn btn-default btn-block btn-xl"
                    onClick={() => {
                      this.setState({
                        showAddDeputy: true,
                        showMakeMe: false,
                        makeMeNameSurnameFromInput: '',
                      });
                    }}
                  >
                    {p.t('addperson')}
                    <span className="glyphicon glyphicon-plus pull-right offset-left" aria-hidden="true" />
                  </button>
                </div>

                {registration.fullPower
                  ? (
                    <div className={classnames("col-sm-6 col-md-4 offset-bottom-sm-max")} >
                      <div className="btn btn-default btn-block btn-xl btn-icon-right active">
                        <div>
                          <h3 className="h2 offset-bottom-none">Plná moc</h3>
                          <p className="small">{registration.fullPowerName}</p>
                        </div>
                        <button
                          type="button"
                          className="btn-link btn-link-inline link-unstyled"
                          onClick={() => { this.toggle('showRemoveFullPower'); }}
                        >
                          <span className="glyphicon glyphicon-trash" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  )
                  : (
                    <div className="col-sm-6 col-md-4 text-center">
                      <input type="file" id="addFile" className="hidden" ref={r => this.legacyRefs["addFile"] = r} onChange={this.addFile.bind(this)} />
                      <button type="button" onClick={() => { this.legacyRefs["addFile"].click(); }} className="btn btn-default btn-block btn-xl">
                        {p.t('acquireattorney')}
                        <span className="glyphicon glyphicon-file pull-right offset-left" aria-hidden="true" />
                      </button>
                    </div>
                  )
                }
              </div>{/* .row */}

              <hr className="offset-bottom-xs-max offset-bottom-large" />
              <div>
                <CheckboxField
                  name="confirmTerms"
                  value={this.state.confirmTerms}
                  onChange={val => this.setState({confirmTerms: val})}
                  error={showErrorHelpers && !this.state.confirmTerms && p.t('requiredfield')}
                >
                  <span className="small text-muted2 offset-bottom-large">
                    {p.t('generalterm1')}
                    <a href={p.t('generaltermslink')} rel="noopener noreferrer" target="_blank" className="link">
                      {p.t('generaltermsandconditions')}
                    </a>
                    {p.t('generalterm2')}
                  </span>
                </CheckboxField>
              </div>
              <hr className="offset-bottom-xs-max offset-bottom-large" /> {/* Navigation */}
              <div className="row">
                <div className="col-sm-5 col-sm-push-7 col-md-4 col-md-push-8 offset-bottom-xs-max">
                  <button type="submit" disabled={!this.state.confirmTerms} className="btn btn-accent btn-block btn-lg text-uppercase text-letterspacing">
                    {p.t('register')}
                  </button>
                </div>
                <div className="col-sm-5 col-sm-pull-5 col-md-4 col-md-pull-4">
                  <Link to={payoutpath} className="btn btn-accent btn-accent-outline btn-block btn-lg btn-icon-left">
                    <span className="glyphicon glyphicon-menu-left" aria-hidden="true" />
                    {p.t('previous')}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal show={this.state.showAddDeputy} onHide={this.toggleShowAddDeputy}>
          <form onSubmit={(e) => {
            e.preventDefault();
            if (this.state.makeMeFirstName.length === 0
              || this.state.makeMeLastName.length === 0
              || (this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail))
            ) {
              this.setState({ showMakeMeErrors: true });
              return false;
            }
            this.setState({ showMakeMeErrors: false });
            this.props.addDeputy({
              email: this.state.makeMeEmail,
              firstName: this.state.makeMeFirstName,
              lastName: this.state.makeMeLastName,
              isDeputy: true,
              madeByHand: true,
              onlyDeputy: true,
            });
            this.setState({ makeMeEmail: '', showAddDeputy: false });
          }}>
            <Modal.Header closeButton>
              <Modal.Title>{p.t('enterrepresentative')}</Modal.Title>
            </Modal.Header>

            {this.state.showMakeMe
              ? (
                <Modal.Body>
                  <div className={classnames("form-group", { "has-error": (this.state.showMakeMeErrors && this.state.makeMeFirstName.length === 0) })}>
                    <label htmlFor="makeMeFirstName" className={classnames("control-label-floating", { active: this.state.makeMeFirstName.length > 0 || isFocusedInput('makeMeFirstName') })}>
                      {p.t('firstname')}
                    </label>
                    <input
                      value={this.state.makeMeFirstName}
                      onChange={this.changeStateField}
                      name="makeMeFirstName"
                      onFocus={() => { this.forceUpdate(); }}
                      onBlur={() => { this.forceUpdate(); }}
                      type="text"
                      id="makeMeFirstName"
                      className="form-control js-form-control"
                    />
                    {(this.state.showMakeMeErrors && this.state.makeMeFirstName.length === 0)
                      && <div className="help-block">{p.t('validfirstname')}</div>
                    }
                  </div>
                  <div className={classnames("form-group", { "has-error": (this.state.showMakeMeErrors && this.state.makeMeLastName.length === 0) })}>
                    <label htmlFor="makeMeLastName" className={classnames("control-label-floating", { active: this.state.makeMeLastName.length > 0 || isFocusedInput('makeMeLastName') })}>
                      {p.t('surname')}
                    </label>
                    <input
                      value={this.state.makeMeLastName}
                      onChange={this.changeStateField}
                      name="makeMeLastName"
                      onFocus={() => { this.forceUpdate(); }}
                      onBlur={() => { this.forceUpdate(); }}
                      type="text"
                      id="makeMeLastName"
                      className="form-control js-form-control"
                    />
                    {(this.state.showMakeMeErrors && this.state.makeMeLastName.length === 0)
                      && <div className="help-block">{p.t('validlastname')}</div>
                    }
                  </div>
                  <div className={classnames("form-group", { "has-error": (this.state.showMakeMeErrors && (this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail))) })}>
                    <label htmlFor="makeMeEmail" className={classnames("control-label-floating", { active: this.state.makeMeEmail.length > 0 || isFocusedInput('makeMeEmail') })}>
                      {p.t('email')}
                    </label>
                    <input
                      value={this.state.makeMeEmail}
                      onChange={this.changeStateField}
                      name="makeMeEmail"
                      onFocus={() => { this.forceUpdate(); }}
                      onBlur={() => { this.forceUpdate(); }}
                      type="text"
                      id="makeMeEmail"
                      className="form-control js-form-control"
                    />
                    {(this.state.showMakeMeErrors && (this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail)))
                      && <div className="help-block">{p.t('inputvalidemail')}</div>
                    }
                  </div>
                </Modal.Body>
              )
              : (
                <Modal.Body>
                  <div className="form-group">
                    <Select
                      resetValue="-1"
                      filterOption={(option, filterString) => option.label.includes(filterString) || option.value === 'new'}
                      options={recountPeopleOptions(registration.people)}
                      onInputChange={(e) => { this.setState({ makeMeNameSurnameFromInput: e }); }}
                      optionRenderer={e => (<div>{e.value === 'new' ? this.state.makeMeNameSurnameFromInput + ' zadat nové' : e.label}</div>)}
                      placeholder={p.t('select')}
                      onChange={(value) => {
                        if (value.value === 'new') {
                          this.setState({
                            makeMeFirstName: this.state.makeMeNameSurnameFromInput.split(' ')[0],
                            makeMeLastName: this.state.makeMeNameSurnameFromInput
                              .split(' ')
                              .filter((oName, index) => index !== 0)
                              .join(' '),
                            showMakeMe: true,
                          });
                        } else {
                          this.props.changeField({ name: ['people', value.value, 'isDeputy'], value: true });
                          this.toggle('showAddDeputy');
                        }
                      }}
                      id={"contact-person-id"}
                    />
                  </div>
                </Modal.Body>
              )
            }

            <Modal.Footer>
              <button className="btn btn-primary pull-right offset-left" >{p.t('appoint')}</button>
              <button
                className="btn btn-default "
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle('showAddDeputy');
                }}>
                {p.t('cancel')}
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal show={this.state.showRemoveDeputy} onHide={() => this.toggle('showRemoveDeputy')}>
          <Modal.Body>{p.t('deleterepresentative')}</Modal.Body>
          <Modal.Footer>
            <button
              ref={r => this.legacyRefs["btn-remove-person-recapitulation"] = r}
              className="btn btn-primary pull-right offset-left"
              onClick={() => {
                this.props.removeDeputy(this.state.indexRemoveDeputyToRemove);
                this.toggle('showRemoveDeputy');
                this.setState({ firstName: '', lastName: '', email: '' });
              }}
            >
              {p.t('delete')}
            </button>
            <button className="btn btn-default " onClick={() => this.toggle('showRemoveDeputy')}>
              {p.t('cancel')}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showRemoveFullPower} onHide={() => this.toggle('showRemoveFullPower')}>
          <Modal.Body>{p.t('deleteattorney')}</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary pull-right offset-left" onClick={() => {
              this.props.changeField({ name: 'fullPower', value: false });
              this.props.changeField({ name: 'fullPowerName', value: '' });
              this.toggle('showRemoveFullPower');
            }}>{p.t('delete')}</button>
            <button className="btn btn-default " onClick={() => this.toggle('showRemoveFullPower')}>{p.t('cancel')}</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showOnlyOneDeputy} onHide={() => this.toggle('showOnlyOneDeputy')}>
          <Modal.Body>{p.t('provideattorney')}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary pull-right offset-left"
              onClick={() => {
                this.toggle('showOnlyOneDeputy');
                this.legacyRefs["addFile"].click();
              }}
            >
              Vložit plnou moc
            </button>
            <button className="btn btn-default " onClick={() => this.toggle('showOnlyOneDeputy')}>{p.t('cancel')}</button>
            <button className="btn btn-default " onClick={this.onSubmit.bind(this)}>{p.t('registerwithout')}</button>
          </Modal.Footer>
        </Modal>
      </main>
    );
  }
}

export default connect(state => ({
  registration: state.registration,
  p: getP(state, { polyglotScope: 'registration' }),
  pErrors: getP(state, { polyglotScope: 'errors' }),
  currentLocale: getLocale(state),
  selectedAccount: selectSelectedAccount(state),
  discountSettingEnabled: selectDiscountSettingEnabled(state),
}), { changeField, removeDeputy, addDeputy, toggleSpinner, sendRegistration }
)(Recapitulation);
