import { RSAA } from "redux-api-middleware";
import { getApiUrl } from "../../common/api";

export const MYSTAY_SEND_REQUEST = 'MYSTAY_SEND_REQUEST';
export const MYSTAY_SEND_SUCCESS = 'MYSTAY_SEND_SUCCESS';
export const MYSTAY_SEND_FAILURE = 'MYSTAY_SEND_FAILURE';

export const myStaySend = (data) => (dispatch, GetState) => {
  const state = GetState();
  return dispatch({
    [RSAA]: {
      endpoint: getApiUrl(state, 'contact_form'),
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(data),
      types: [
        MYSTAY_SEND_REQUEST,
        MYSTAY_SEND_SUCCESS,
        MYSTAY_SEND_FAILURE,
      ],
    },
  })
};