import errors from '../assets/i18n/errors.json';
import routeMessages from '../assets/i18n/routes.json';
import cs from '../assets/i18n/cs.json';
import en from '../assets/i18n/en.json';
import sk from '../assets/i18n/sk.json';
import instanceSkMessages from '../assets/i18n/sk-instance.json';
import instanceCzMessages from '../assets/i18n/cz-instance.json';
import { INSTANCE } from './browserConfig';

export { routeMessages };

function getLangPhrases(obj, lang) {
	return Object.keys(obj).reduce((acc, curr) => { acc[curr] = errors[curr][lang]; return acc; }, {});
}

function mergeNamespaces(msgs1, msgs2) {
	const ret = {};

	Object.keys(msgs1).forEach(curr => {
		if (ret[curr] === undefined) {
			ret[curr] = {};
		}

		Object.assign(ret[curr], msgs1[curr]);
	});

	Object.keys(msgs2).forEach(curr => {
		if (ret[curr] === undefined) {
			ret[curr] = {};
		}

		Object.assign(ret[curr], msgs2[curr]);
	});

	return ret;
}

const instanceMessages = INSTANCE === 'sk' ? instanceSkMessages : instanceCzMessages;

const messages = {
	"cs": {
		...mergeNamespaces(cs, instanceMessages),
		"errors": getLangPhrases(errors, 'cs'),
		"routes": routeMessages['cs'],
	},
	"sk": {
		...mergeNamespaces(sk, instanceMessages),
		"errors": getLangPhrases(errors, 'sk'),
		"routes": routeMessages['sk'],
	},
	"en": {
		...mergeNamespaces(en, instanceMessages),
		"errors": getLangPhrases(errors, 'en'),
		"routes": routeMessages['en'],
	},
}

export default messages;