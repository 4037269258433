// @flow
import React from 'react';
import { PanelGroup, Panel, Row } from 'react-bootstrap';
import { Marker } from "react-google-maps"
import { INSTANCE_SK } from '../../browserConfig';
import type { ShopType } from '../../../flow/state';
import type { TranslateP } from '../../../flow/model';
import Map from './Map';

type Props = {
	shops: ShopType[],
	affil_types: {},
	people: {},
	cash_registers: {},
	p: TranslateP,
};

type State = {
	openKey: string,
};

export default class ShopsPanel extends React.Component<Props, State> {

	state: State = {
		openKey: '',
	};

	mapRefs: any[] = [];

	handleSelect = (openKey: string) => {
		this.setState({ openKey });
	}

	handlePanelToggle(key: string, show: boolean) {
		if(show) {
			this.setState({[key]: show});
		} else {
			setTimeout(() => this.setState({[key]: show}), 300);
		}
	}

	render() {
		const { p, shops, affil_types, people, cash_registers } = this.props;

		return (
			<PanelGroup
				accordion
				activeKey={this.state.openKey}
				onSelect={this.handleSelect}
				id="shops-panel"
			>
			{shops.map((shop, index) => (
				<Panel key={index} eventKey={`shop${index}`} bsStyle="primary" onToggle={show => this.handlePanelToggle(`shop${index}`, show) }>
					<Panel.Heading>
						<Panel.Title toggle>
							<Row>
								<div className="col-xs-10">
									<h2 className="panel-title">
										{shop.name}
										<br className="visible-xs" />
										<span className="hidden-xs offset-left offset-right">|</span>
										<span className="small">{shop.address}</span>
									</h2>
								</div>
								<div className="col-xs-2 text-right">
									<span className="glyphicon glyphicon-menu-down" aria-hidden="true" />
								</div>
							</Row>
						</Panel.Title>
					</Panel.Heading>
					<Panel.Body collapsible>
					<Row>
						<div className="col-sm-6 col-md-8 offset-bottom-xs-max">
							<p className="offset-bottom-small">
								<strong>{shop.name}</strong><br />
								{shop.address}<br />
								{shop.city}<br />
								{shop.postalCode}
							</p>
							<p className="offset-bottom-none">
								<strong>{p.t('shop_type')}:</strong> {affil_types[shop.c23_branch_type_id]}
							</p>
							<div className="map-bordered map-small offset-top">
								{this.state[`shop${index}`] && <Map
									containerElement={<div />}
									center={{ lat: shop.lat, lng: shop.lng }}
									mapRef={r => this.mapRefs[index] = r}
								>
									<Marker position={{ lat: shop.lat, lng: shop.lng }} />
								</Map>}
							</div>
						</div>

						<div className="col-sm-6 col-md-4">

							<h3 className="offset-top-none">{p.t('contactdetails')}</h3>
							<div className="offset-bottom-large">
								<div>
									{people[shop.contactPersonId].firstName}
									{people[shop.contactPersonId].lastName}
								</div>
								<div><a href={"tel: " + shop.telephoneForHosts} className="link text-nowrap">{shop.telephoneForHosts}</a></div>
								<div><a href={"mailto:" + shop.emailForHosts} className="link">{shop.emailForHosts}</a></div>
								<div><a href={shop.website} className="link">{shop.website}</a></div>
							</div>

							<h3 className="offset-bottom">{INSTANCE_SK ? p.t('paymentterminals') : p.t('paymentmethods')}</h3>
							{INSTANCE_SK && shop.pos && shop.pos.length > 0 &&
							<div>
								{p.t('paymentterminalslist')}<br />{shop.pos.map((pos, i) => <div key={i}>{pos.n01_tid}</div>)}
							</div>}
							{INSTANCE_SK && (!shop.pos || shop.pos.length === 0) && <div>{p.t('nopaymentterminals')}</div>}
							{!INSTANCE_SK && <table className="table table-condensed">
								<tbody>
									<tr>
										<th>{p.t('posterminal')}</th>
										<td>
											{shop.terminal
												?
												<span>
													<span className="glyphicon glyphicon-ok-sign text-success icon-shift-down offset-right" aria-hidden="true" />
													{p.t('yes')}
												</span>
												: <span>{p.t('no')}</span>}
										</td>
									</tr>
									<tr>
										<th>{p.t('banksystem')}</th>
										<td>{cash_registers[shop.payoutSystem]}</td>
									</tr>
								</tbody>
							</table>}

						</div>
					</Row>
					</Panel.Body>
				</Panel>
			))}
			</PanelGroup>
		);
	}
}