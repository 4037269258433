import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { getP, getLocale } from 'redux-polyglot';
import { changeField } from '../../common/registration/actions';
import $ from 'jquery';
import Header, { getNextLangIndex, LANGUAGES } from '../app/Header.react';
import { tRouteLocale } from '../createRoutes';

export class RegistrationHeader extends Component {
  
  render() {
    const { location, p, currentLocale } = this.props;
    const langIdx = getNextLangIndex(currentLocale);
    const links = ['basic-data', 'shops', 'payout', 'posted', 'recapitulation'];
    const changedPath = tRouteLocale(`/lang/registration/${links.find(p => location.pathname.includes(tRouteLocale(`/${p}`, currentLocale)))}`, LANGUAGES[langIdx].lang);

    return (
      <header id="top" role="banner" className="site-header hidden-print">
        <Header p={p} location={location} currentLocale={currentLocale} title={p.t('registration')}>
          <li>
            <Link to="#help" onClick={(e) => {
              e.preventDefault();
              let whereToScroll = $('#footer-content-help').offset().top;
              $('html').animate({scrollTop:whereToScroll}, 'slow'); //IE, FF
              $('body').animate({scrollTop:whereToScroll}, 'slow'); //chrome, don't know if Safari works
            }} className="js-scroll">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="navbar-nav-icon offset-right" viewBox="0 0 32 32">
              <path fill="#86939e" d="M16 0q3.25 0 6.214 1.268t5.107 3.411 3.411 5.107 1.268 6.214-1.268 6.214-3.411 5.107-5.107 3.411-6.214 1.268-6.214-1.268-5.107-3.411-3.411-5.107-1.268-6.214 1.268-6.214 3.411-5.107 5.107-3.411 6.214-1.268zM16 2.286q-3.393 0-6.446 1.607l3.464 3.464q1.464-0.5 2.982-0.5t2.982 0.5l3.464-3.464q-3.054-1.607-6.446-1.607zM3.893 22.446l3.464-3.464q-0.5-1.464-0.5-2.982t0.5-2.982l-3.464-3.464q-1.607 3.054-1.607 6.446t1.607 6.446zM16 29.714q3.393 0 6.446-1.607l-3.464-3.464q-1.464 0.5-2.982 0.5t-2.982-0.5l-3.464 3.464q3.054 1.607 6.446 1.607zM16 22.857q2.839 0 4.848-2.009t2.009-4.848-2.009-4.848-4.848-2.009-4.848 2.009-2.009 4.848 2.009 4.848 4.848 2.009zM24.643 18.982l3.464 3.464q1.607-3.054 1.607-6.446t-1.607-6.446l-3.464 3.464q0.5 1.464 0.5 2.982t-0.5 2.982z"></path>
            </svg>
            {p.t('help')}
            </Link>
          </li>
          {/*<li role="separator" className="divider" />*/}
          {/* <li><Link to="/registrace/pokracovat" className="js-scroll">Seznam registrací</Link></li> */}
          <li role="separator" className="divider" />
          <li><Link onClick={
            () => {
              if (window.innerWidth < 991) {
                $('#menu-toggle').click();
              }
            }} to={changedPath}>{LANGUAGES[langIdx].label}</Link>
          </li>
        </Header>
      </header>
    );
  }
}

export default connect(state => ({
 registration: state.registration,
 p: getP(state, { polyglotScope: 'registration' }),
 currentLocale: getLocale(state),
}), { changeField})(RegistrationHeader);
