import React, { PureComponent } from 'react';

export default class Footer extends PureComponent {

  render() {
    return (
      <footer id="footer-content-help"  role="contentinfo" className="site-footer hidden-print">
        {this.props.children}
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                © Up Déjeuner, s.r.o., 2021
              </div>
              <div className="col-md-3">
                {/*<a href={p.t('gustokartaWebUrl')} target="_blank" className="footer-author" title="Created by GUSTO KARTA" rel="noopener noreferrer">*/}
                {/*  {`Created by GUSTO KARTA`}*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
