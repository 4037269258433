import { Record } from '../transit';
import { phonePrefixDefault } from '../constants';

const Shop = Record({
  address: '', // set by geosuggest => label
  bank: 1,
  c23_branch_type_id: '',
  city: '',
  contactPersonFirstname: '',
  contactPersonId: -1,
  contactPersonLastname: '',
  editingPosition: false,
  emailForHosts: '',
  googleAddress: {}, // probably - address set only by search by IC API. NOT USED? TODO: rename to "original"
  googlePlaceId: '', // set by geosuggest
  hardCodedPosition: false,
  idCashier: '',
  lat: '',  // set by geosuggest
  lng: '',
  positionChanged: false, /** positon has been changed by user (geosuggest or map movement) */
  name: '',
  payoutSystem: '',
  postalCode: '',
  street: '',
  streetNr: '',
  telephoneForHosts: phonePrefixDefault,
  terminal: false,
  website: '',
  pos: null,
  posValid: false,
  posDisabled: false,
}, 'shop');

export default Shop;
