import React, { PureComponent } from 'react';
import translate from 'redux-polyglot/translate';
import Footer from '../app/Footer.react';
import iconPhone from '../../assets/images/icon_phone.svg';
import iconEnvelope from '../../assets/images/icon_envelope.svg';

export class RegistrationFooter extends PureComponent {
  render() {
    const { p } = this.props;

    return (<Footer p={p}>
      <section className="section-content bg-primary text-center" id="help">
        <div className="container">
          <h2 className="display-2-big">{p.t('dontknow')}</h2>
          <div className="row">
            <div className="col-md-6 offset-bottom-large-sm-max">
              <h3 className="h4 text-regular text-uppercase">
                <img src={iconPhone} width={25} height={25} className="offset-right icon-shift-up" alt="Telefon" />
                {p.t('lifesupport')}
              </h3>
              <p>
                <a href={`tel:${p.t('lifesupportPhone').replace(/[^0-9/+]+/g, '')}`} className="display-2 link-unstyled text-light text-nowrap">{p.t('lifesupportPhone')}</a>
              </p>
            </div>
            <div className="col-md-6">
              <h3 className="h4 text-regular text-uppercase">
                <img src={iconEnvelope} width={25} height={25} className="offset-right icon-shift-up" alt="E-mail" />
                {p.t('emailsupport')}
              </h3>
              <p>
                <a href={`mailto:${p.t('emailsupportAddress')}`} className="display-2 text-light link-unstyled">{p.t('emailsupportAddress')}</a>
              </p>
            </div>
          </div>{/* .row */}
        </div>
      </section>
    </Footer>);
  }
}

export default translate({ polyglotScope: 'registration' }, RegistrationFooter);
