// @flow
import React from "react";
import Select from "react-select";
import {
  Row,
  Col,
  Table,
  FormGroup,
  FormControl,
  HelpBlock,
  Glyphicon,
  Button
} from "react-bootstrap";
import FontAwesome from 'react-fontawesome';

import type { PaymentTerminalRow } from "../../../flow/model";

type Props = {
  p: any,
  posBanksList: LabelValueList,
  posSoList: LabelValueList,
  rows: PaymentTerminalRow[];
  showErrorHelpers: boolean;
  onChange: (rows: PaymentTerminalRow[]) => void;
  onValid: (valid: boolean|null) => void;
  checkPOS: (posIndex: number) => Promise<any>;
};

type State = {
  valid: any;
  errorMessages: { [row: number]: {[prop: string]: string }};
  showErrorHelpers: boolean;
};

export class PaymentTerminals extends React.Component<Props, State> {
  state = {
    valid: {},
    errorMessages: {},
    showErrorHelpers: false,
  };

  validationTimeoutHandler = null;
  validationDebounce = 500;

  static defaultProps = {};

  componentDidMount() {
    // initial validation
    this.triggerAllValidation();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.terminalDisabled !== this.props.terminalDisabled || prevProps.rows.length !== this.props.rows.length) {
      this.triggerAllValidation();
    }
  }

  handleTerminalsDisable = (val) => {
    if (val) {
      this.props.onChange([], val);
      this.updateValidity(null); // reset validation data and set as valid
    } else {
      this.props.onChange([{}], val);
      this.triggerValidation(0, [{}]);
    }
  }

  handleChange(index: number, prop: string, val: string) {
    const newRows = this.props.rows.map((row, idx) => idx === index ? { ...row, [prop]: val } : row );

    // commented out: All rows are now required (including the first one)
    // if (newRows.length === 1 && isRowEmpty(newRows[0])) {
    //   // last empty row => remove it.
    //   this.props.onChange([]);
    //   this.updateValidity(null);
    //   return;
    // }

    this.props.onChange(newRows, this.props.terminalDisabled);
    this.triggerValidation(index, newRows);
  }

  handleRemove(index: number) {
    const newRows = this.props.rows.filter((row, idx) => idx !== index);
    this.props.onChange(newRows, this.props.terminalDisabled);
    this.updateValidity(index, undefined);
  }

  validateRow(row: PaymentTerminalRow) {
    const { p } = this.props;
    const errors = {};

    const required = ['n01_tid'];

    required.forEach(field => {
      if (!row[field]) {
        errors[field] = p.t('requiredvalidation');
      }
    });

    return errors;
  }

  updateValidity(index: number, valid: boolean|null|undefined, message: string|{[prop: string]: string}|null) {
    let newValid = {};
    let newMessages = {};
    
    if (index !== null) {
      newValid = { ...this.state.valid };

      if (valid === undefined) {
        delete newValid[index];
      } else {
        newValid[index] = valid;
      }
      newMessages = { ...this.state.errorMessages };
      newMessages[index] = typeof message === 'string' ? { n01_tid: message } : message;
    }

    this.setState({ valid: newValid, errorMessages: newMessages });

    const allValid = Object.keys(newValid).reduce((acc, curr, idx, arr) => {
      if (newValid[curr] === true) {
        return acc;
      } else if(newValid[curr] === null || acc === null) {
        return null;
      } else {
        return newValid[curr];
      }
    }, true);

    this.props.onValid(allValid);
  }

  triggerAllValidation() {
    if (this.props.terminalDisabled) {
      this.updateValidity(null); // reset validation data and set as valid
    } else {
      this.props.rows.forEach((row, idx) => this.triggerValidation(idx, this.props.rows));
    }
  }

  triggerValidation(index: number, rows: PaymentTerminalRow[]) {
    const errors = this.validateRow(rows[index]);

    if (Object.keys(errors).length > 0) {
      this.updateValidity(index, false, errors);
      return;
    }

    this.updateValidity(index, null, this.props.p.t('paymentterminalvalidating'));

    if (this.validationTimeoutHandler) {
      clearTimeout(this.validationTimeoutHandler);
      this.validationTimeoutHandler = null;
    }
    this.validationTimeoutHandler = setTimeout(() => {
      this.props.checkPOS(index).then(res => {
        const valid = !res.error;
        const message = res.payload.status === 400 ? res.payload.response.message : null;
        // console.log('validated', !valid ? 'INVALID' : 'OK', res.payload);
        this.updateValidity(index, valid, message);
        this.setState({ showErrorHelpers: true });
      });
    }, this.validationDebounce);
  }

  renderRow = (data: PaymentTerminalRow, index: number) => {
    const { p, posSoList, posBanksList } = this.props;
    const valid = this.state.valid[index];
    const showErrorHelpers = this.props.showErrorHelpers || this.state.showErrorHelpers;
    const validationState = valid === true ? 'success' : ((valid === false && showErrorHelpers) ? 'error' : null);
    const errorMessage = this.state.errorMessages[index];
    return (
      <tr key={index}>
        <td>
          <FormGroup validationState={validationState}>
            <FormControl
              type="text"
              value={data.n01_tid || ''}
              placeholder={p.t('entertext')}
              onChange={e => this.handleChange(index, 'n01_tid', e.target.value)}
            />
            {/* <FormControl.Feedback /> */}
            {showErrorHelpers && errorMessage && errorMessage.n01_tid && <HelpBlock>{errorMessage.n01_tid}</HelpBlock>}
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <FormControl
              type="text"
              value={data.n01_mid || ''}
              placeholder={p.t('entertext')}
              onChange={e => this.handleChange(index, 'n01_mid', e.target.value)}
            />
            {/* <FormControl.Feedback /> */}
            {showErrorHelpers && errorMessage && errorMessage.n01_mid && <HelpBlock>{errorMessage.n01_mid}</HelpBlock>}
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <FormControl
              bsClass={null}
              componentClass={Select}
              options={posSoList}
              value={data.x01_partner_id || ''}
              onChange={val => this.handleChange(index, 'x01_partner_id', val.value)}
              resetValue=""
              placeholder={p.t("select")}
            />
            {showErrorHelpers && errorMessage && errorMessage.x01_partner_id && <HelpBlock>{errorMessage.x01_partner_id}</HelpBlock>}
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <FormControl
              bsClass={null}
              componentClass={Select}
              options={posBanksList}
              value={data.n90_pos_bank_id || ''}
              onChange={val => this.handleChange(index, 'n90_pos_bank_id', val.value)}
              resetValue=""
              placeholder={p.t("select")}
            />
            {showErrorHelpers && errorMessage && errorMessage.n90_pos_bank_id && <HelpBlock>{errorMessage.n90_pos_bank_id}</HelpBlock>}
          </FormGroup>
        </td>
        <td>
          <div className="button-wrapper">
            <Button className="btn-circle" bsSize="large" bsStyle="danger" onClick={() => this.handleRemove(index)} disabled={this.props.rows.length <= 1 && isRowEmpty(this.props.rows[0])}>
              <Glyphicon glyph="trash" className="icon-shift-down" />
            </Button>
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { p, rows } = this.props;
    return (
      <>
      <Row>
        <Col xs={12}>
          {!this.props.terminalDisabled && <Table className="u-margin-bottom-none">
            <thead>
              <tr>
                <th className="col-min-150">{p.t("posTID")}</th>
                <th className="col-min-150">{p.t("posMID")}</th>
                <th className="col-min-150">{p.t("posserviceorganization")}</th>
                <th className="col-min-150">{p.t("posbank")}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>{rows.map(this.renderRow)}</tbody>
          </Table>}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Button className="aligner" active={this.props.terminalDisabled} onClick={() => this.handleTerminalsDisable(!this.props.terminalDisabled)}>
            <FontAwesome size="2x" name={"unlink"} />
            <span className="text-bolder u-margin-left"> {p.t('shophasnoterminal')}</span>
          </Button>
        </Col>
      </Row>
      </>
    );
  }
}

export function mapToList(cashRegisters) {
  const allRegisters = [];
  for (let i in cashRegisters) {
    if (cashRegisters.hasOwnProperty(i)) {
      allRegisters.push({ value: i, label: cashRegisters[i] });
    }
  }

  return allRegisters;
}

export function isRowEmpty(row) {
  return !Object.keys(row).some(k => !!row[k]);
}
