// @flow
import { RSAA } from "redux-api-middleware";
import { getP } from 'redux-polyglot';
import type { ShopType } from "../../flow/state";
import type { Action, CompanyType, ImportedShopType } from "../../flow/model";
import { getApiUrl } from "../api";
import { toggleSpinner } from "../app/actions";

export const ADD_HUNDRED_TODOS = 'ADD_HUNDRED_TODOS';
export const ADD_TODO = 'ADD_TODO';
export const CLEAR_ALL_COMPLETED_TODOS = 'CLEAR_ALL_COMPLETED_TODOS';
export const CLEAR_ALL_TODOS = 'CLEAR_ALL_TODOS';
export const DELETE_TODO = 'DELETE_TODO';
export const TOGGLE_TODO_COMPLETED = 'TOGGLE_TODO_COMPLETED';
export const ADD_COMPANY_BY_IC = 'ADD_COMPANY_BY_IC';
export const CHANGE_FIELD = 'CHANGE_FIELD';
export const SELECT_ME = 'SELECT_ME';
export const ADD_ME = 'ADD_ME';
export const REMOVE_ME = 'REMOVE_ME';
export const ADD_DEPUTY = 'ADD_DEPUTY';
export const REMOVE_DEPUTY = 'REMOVE_DEPUTY';
export const ADD_SHOP = 'ADD_SHOP';
export const REMOVE_SHOP = 'REMOVE_SHOP';
export const RESET_COMPANY = 'RESET_COMPANY';
export const CHANGE_SHOP_CENTER = 'CHANGE_SHOP_CENTER';
export const ADD_SHOP_FROM_FILE = 'ADD_SHOP_FROM_FILE';
export const SHOW_ERROR = 'SHOW_ERROR';

export const CHECK_POS_REQUEST = 'CHECK_POS_REQUEST';
export const CHECK_POS_SUCCESS = 'CHECK_POS_SUCCESS';
export const CHECK_POS_FAILURE = 'CHECK_POS_FAILURE';

export const CHECK_DEALER_CODE_REQUEST = 'CHECK_DEALER_CODE_REQUEST';
export const CHECK_DEALER_CODE_SUCCESS = 'CHECK_DEALER_CODE_SUCCESS';
export const CHECK_DEALER_CODE_FAILURE = 'CHECK_DEALER_CODE_FAILURE';

export const SEND_REGISTRATION_REQUEST = 'SEND_REGISTRATION_REQUEST';
export const SEND_REGISTRATION_SUCCESS = 'SEND_REGISTRATION_SUCCESS';
export const SEND_REGISTRATION_FAILURE = 'SEND_REGISTRATION_FAILURE';

export const CHECK_IBAN_REQUEST = 'CHECK_IBAN_REQUEST';
export const CHECK_IBAN_SUCCESS = 'CHECK_IBAN_SUCCESS';
export const CHECK_IBAN_FAILURE = 'CHECK_IBAN_FAILURE';

export type ChangeFieldType =  {
  name: string|string[];
  value: any;
}

export type Actions = 
    Action<'ADD_COMPANY_BY_IC', { oCompany: CompanyType }>
  | Action<'RESET_COMPANY', { oCompany: CompanyType }>
  | Action<'CHANGE_FIELD', ChangeFieldType>
  | Action<'SHOW_ERROR', { oError: any }>
  | Action<'CHANGE_SHOP_CENTER', {
    name: string,
    lat: number,
    lng: number,
  }>
  | Action<'SELECT_ME', { index: number }>
  | Action<'ADD_SHOP_FROM_FILE', { oShop: ImportedShopType }>
  | Action<'ADD_ME', { oMe: any }>
  | Action<'REMOVE_SHOP', { indexShopToRemove: number }>
  | Action<'REMOVE_ME', { indexToRemove: number }>
  | Action<'ADD_DEPUTY', { oDeputy: any }>
  | Action<'REMOVE_DEPUTY', { indexRemoveDeputyToRemove: number }>
  | Action<'ADD_SHOP', { oShop: ShopType}>
  | Action<CHECK_DEALER_CODE_SUCCESS>
  | Action<CHECK_DEALER_CODE_FAILURE>
;

export function addCompanyByIc(oCompany: CompanyType): Actions {
  return {
    type: ADD_COMPANY_BY_IC,
    payload: { oCompany },
  };
}

export function resetCompany(oCompany: CompanyType): Actions {
  return {
    type: RESET_COMPANY,
    payload: { oCompany },
  };
}

export function changeField(oChange: ChangeFieldType): Actions {
  return {
    type: CHANGE_FIELD,
    payload: {
      name: oChange.name,
      value: oChange.value,
    },
  };
}

export function showError(oError): Actions {
  return {
    type: SHOW_ERROR,
    payload: oError,
  };
}

export function changeShopCenter(oChange): Actions {
  return {
    type: CHANGE_SHOP_CENTER,
    payload: {
      name: oChange.name,
      lat: oChange.lat,
      lng: oChange.lng,
      manualPosition: oChange.manualPosition || false,
    },
  };
}

export function selectMe(index: number): Actions {
  return {
    type: SELECT_ME,
    payload: { index },
  };
}

export function addShopFromFile(oShop: ImportedShopType): Actions {
  return {
    type: ADD_SHOP_FROM_FILE,
    payload: { oShop },
  };
}
export function addMe(oMe): Actions {
  return {
    type: ADD_ME,
    payload: { oMe },
  };
}

export function removeShop(indexShopToRemove: number): Actions {
  return {
    type: REMOVE_SHOP,
    payload: { indexShopToRemove },
  };
}
export function removeMe(indexToRemove: number): Actions {
  return {
    type: REMOVE_ME,
    payload: { indexToRemove },
  };
}

export function addDeputy(oDeputy): Actions {
  return {
    type: ADD_DEPUTY,
    payload: { oDeputy },
  };
}
export function removeDeputy(indexRemoveDeputyToRemove: number): Actions {
  return {
    type: REMOVE_DEPUTY,
    payload: { indexRemoveDeputyToRemove },
  };
}

export function addShop(oShop: ShopType): Actions {
  return {
    type: ADD_SHOP,
    payload: { oShop },
  };
}

export const checkPOS = (shopIndex: number, posIndex: number) => (dispatch, GetState) => {
  const state = GetState();
  return dispatch({
    [RSAA]: {
      endpoint: getApiUrl(state, `pos_check`),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(state.registration.shops.get(shopIndex).pos[posIndex]),
      types: [
        CHECK_POS_REQUEST,
        CHECK_POS_SUCCESS,
        CHECK_POS_FAILURE,
      ],
    },
  })
};

export const checkIBAN = (iban: string) => (dispatch, GetState) => {
  const state = GetState();
  return dispatch({
    [RSAA]: {
      endpoint: getApiUrl(state, `iban_check/${iban}`),
      method: 'GET',
      types: [
        CHECK_IBAN_REQUEST,
        CHECK_IBAN_SUCCESS,
        CHECK_IBAN_FAILURE,
      ],
    },
  })
};

export const checkDealerCode = (dealerCode: string) => (dispatch, GetState) => {
  const state = GetState();
  return dispatch({
    [RSAA]: {
      endpoint: getApiUrl(state, `registration/check_dealer_code/${dealerCode}`),
      method: 'GET',
      types: [
        CHECK_DEALER_CODE_REQUEST,
        CHECK_DEALER_CODE_SUCCESS,
        CHECK_DEALER_CODE_FAILURE,
      ],
    },
  }).then(({ error, payload }) => {
    const p = getP(state, { polyglotScope: 'registration' });
    const pErrors = getP(state, { polyglotScope: 'errors' });

    dispatch(changeField({ name: 'wrongAgentureCode', value: error }));
    dispatch(changeField({ name: 'showError', value: true }));
    dispatch(changeField({ name: 'showErrorClass', value: error ? 'error' : 'success' }));

    if (error) {
      dispatch(changeField({ name: 'showErrorText', value: payload.response.message || pErrors.t(payload.response.code)}));
    } else {
      dispatch(changeField({ name: 'showErrorText', value: p.t('agencycodechecked', payload) }));
    }
  });
};

export const sendRegistration = (data) => (dispatch, GetState) => {
  const state = GetState();
  dispatch(toggleSpinner());
  return dispatch({
    [RSAA]: {
      endpoint: getApiUrl(state, `registration`),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data),
      types: [
        SEND_REGISTRATION_REQUEST,
        SEND_REGISTRATION_SUCCESS,
        SEND_REGISTRATION_FAILURE,
      ],
    },
  }).then(res => {
    const pErrors = getP(state, { polyglotScope: 'errors' });

    if (res.error) {
      const errorCode = res.payload.response ? +res.payload.response.code : 9999;
      dispatch(changeField({ name: 'showError', value: true }));
      dispatch(changeField({ name: 'showErrorText', value: (res.payload.response && res.payload.response.message) || pErrors.t(errorCode) }));
    } else {
      const agreement = res.payload.files.filter(oneFile => +oneFile.f04_code === 3);
      const conditions = res.payload.files.filter(oneFile => +oneFile.f04_code === 8);
      const conditionsUrl = conditions.length > 0 ? conditions[0].link : '';
      const agreementUrl = agreement.length > 0 ? agreement[0].link : '';

      dispatch(changeField({ name: 'conditionsUrl', value: conditionsUrl }));
      dispatch(changeField({ name: 'agreementUrl', value: agreementUrl }));
    }

    dispatch(toggleSpinner());
    return !res.error;
  });
};


