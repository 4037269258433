// @flow
import React from 'react';
// import classnames from 'classnames';
import { Button, Glyphicon } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

export const IconButton = ({ label, title, icon, onClick, ...rest }) => (
	<div className="form-group">
	<Button title={title || label} onClick={onClick} block {...rest}>
		<span className="btn-icon-left">
			{icon && icon.startsWith('fa-') ? <FontAwesome name={icon.slice(3)} /> : <Glyphicon glyph={icon} />}
			{label}
		</span>
	</Button>
</div>
);

