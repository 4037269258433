import Helmet from 'react-helmet';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getP } from 'redux-polyglot';

export class Finished extends Component {

  render() {
    const { conditionsUrl, agreementUrl, p } = this.props;

    return (
      <main id="content" role="main" className="site-content aligner bg-gray-lighter">
        <Helmet
          title={p.t('completed')}
        />
        <div className="section-content aligner-item">
          <div className="container">
            <div className="box box-wide center-block text-center">
              <h1>{p.t('thanks')}</h1>
              <p className="lead">
                {p.t('pleased')}
              </p>
              <p>
                {p.t('process')}
              </p>
              <p>
                {p.t('encounter')}<a href={`tel:${p.t('lifesupportPhone').replace(/[^0-9/+]+/g, '')}`}>{p.t('lifesupportPhone')}</a>{p.t('finishedor')}<a href={`mailto:${p.t('emailsupportAddress')}`}>{p.t('emailsupportAddress')}</a>.
              </p>
              <p className="offset-bottom-large">
                {p.t('lookingforward1')}<br />
                {p.t('lookingforward2')}</p>
              <p>
                <a href={agreementUrl} className="btn btn-accent btn-block btn-lg btn-icon-right">
                  {p.t('pdfagreement')}
                  <span className="glyphicon glyphicon-menu-right" aria-hidden="true" />
                </a>
                <a href={conditionsUrl} className="btn btn-accent btn-block btn-lg btn-icon-right">
                  {p.t('pdfagreement2')}
                  <span className="glyphicon glyphicon-menu-right" aria-hidden="true" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>

    );
  }

}

export default connect(state => ({
  conditionsUrl: state.registration.conditionsUrl,
  agreementUrl: state.registration.agreementUrl,
  p: getP(state, { polyglotScope: 'registration' }),
}), {})(Finished);
