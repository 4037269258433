// @flow
import React from 'react';
import classnames from 'classnames';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

type FieldGroupValue = string|number;

type Props = {
	id?: string;
	name: string;
	value: FieldGroupValue;
	type?: "text"|"number"|"textarea";
	label: string;
	error?: string;
	min?: string|number;
	max?: string|number;
	onChange: (value: FieldGroupValue, name: string) => void;
};

type State = {
	focused: boolean;
};

export default class FieldGroup extends React.Component<Props, State> {
	state = {
		focused: false,
	};

	static defaultProps = {
		type: 'text',
		error: undefined,
	};

	handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
		if (this.props.onChange) {
			this.props.onChange(e.currentTarget.value, e.currentTarget.name);
		}
	}

	handleFocus = () => {
		this.setState({ focused: true });
	};

	handleBlur = () => {
		this.setState({ focused: false });
	};

	render() {
		const { id, name, value, type, label, error, onChange, ...restProps } = this.props;
		const { focused } = this.state;

		const controlType = type !== 'textarea' ? type : undefined;
		const componentClass = type === 'textarea' ? 'textarea' : undefined;

		return (
			<FormGroup
			controlId={id || name}
			validationState={error ? 'error' : null}
		>
			<ControlLabel
				className={classnames("control-label-floating", { 'active': String(value).length > 0 || focused })}
			>{label}</ControlLabel>
			<FormControl
				{...restProps}
				name={name}
				type={controlType}
				componentClass={componentClass}
				value={value || ''}
				// placeholder={''}
				onChange={this.handleChange}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
			/>
			<FormControl.Feedback />
			{error && <HelpBlock>{error}</HelpBlock>}
		</FormGroup>
		);
	}
}
