import PropTypes from 'prop-types';
import React from 'react';
import { addCompanyByIc, changeField } from '../../common/registration/actions';
import { toggleSpinner } from '../../common/app/actions';
import { connect } from 'react-redux';
import { getP, getLocale } from 'redux-polyglot';
import $ from 'jquery';
import { API_URL, API_VERSION, WEB_URL } from '../browserConfig';
import { tRouteLocale } from '../createRoutes';

class RegistrationForIC extends React.Component {
  componentDidMount() {
    this.props.toggleSpinner();
    this.onStartRegistration();
  }

  onStartRegistration() {
    const { location, pErrors, currentLocale } = this.props;

    const icToSearch = location.query.ic && location.query.ic.split('/')[0];

    if (!/[0-9]+/.test(icToSearch)) {
      this.props.changeField({ name: 'showError', value: true });
      this.props.changeField({ name: 'showErrorText', value: pErrors.t(1) });
      return;
    }

    $.ajax({
      url: `${API_URL}${API_VERSION}/${currentLocale}/registration/search/${icToSearch}`,
      method: 'GET',
      success: (data) => {
        this.props.toggleSpinner();

        this.props.addCompanyByIc(data);
        this.props.router.push(tRouteLocale('/lang/registration/basic-data', this.props.currentLocale));
      },
      error: (jqXHR) => {
        this.props.toggleSpinner();

        const errorCode = 1 * jqXHR.responseJSON.code;
        window.location.href = `${WEB_URL}${this.props.currentLocale}/${tRouteLocale('registration', this.props.currentLocale)}?errorCode=${errorCode}`;
      },
    });
  }

  render() {
    return null;
  }
}

RegistrationForIC.propTypes = {
  addCompanyByIc: PropTypes.func,
  changeField: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  registration: PropTypes.object,
  toggleSpinner: PropTypes.func,
};

export default connect(state => ({
  ic: state,
  registration: state.registration,
  routing: state.routing,
  // p: getP(state, { polyglotScope: 'registration' }),
  pErrors: getP(state, { polyglotScope: 'errors' }),
  currentLocale: getLocale(state),
}), { addCompanyByIc, toggleSpinner, changeField })(RegistrationForIC);