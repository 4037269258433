import React, { PureComponent } from 'react';
import RegistrationHeader from './RegistrationHeader.react';
import RegistrationFooter from './RegistrationFooter.react';
import Notifications from '../app/Notifications.react';

class RegistrationPage extends PureComponent {
  render() {
    const { children, location } = this.props;

    return (
      <div className="site site-fixed-header">
        <RegistrationHeader location={location} />
        <Notifications />
        {children}
        <RegistrationFooter />
      </div>
    );
  }

}

export default RegistrationPage;
