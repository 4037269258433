import React, { Component } from 'react';
import { withGoogleMap, GoogleMap } from "react-google-maps";
import reactMixin from 'react-mixin';
import { ReactScriptLoaderMixin } from 'react-script-loader';
import { mapStyles } from '../../../common/constants';
import { GOOGLE_MAPS_KEY } from '../../browserConfig';
import { mobileTabletcheck } from '../../browserCommonFunctions';

const MapComponent = withGoogleMap(({ mapRef, editingPosition, ...props }) =>
	<GoogleMap
		ref={mapRef}
		defaultOptions= {{styles: mapStyles}}
		options={{scrollwheel: false, draggable: (!mobileTabletcheck() || editingPosition)}}
		defaultZoom={16}
		{...props}
	>
	</GoogleMap>
);

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptLoading: true,
      scriptLoadError: false,
      scriptUrl: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_KEY}&libraries=places&language=cs`,
    };
  }

  onScriptLoaded() {
    this.setState({ scriptLoading: false });
  }

  onScriptError() {
    this.setState({ scriptLoading: false, scriptLoadError: true });
  }

  getScriptURL() {
    return this.state.scriptUrl;
  }

  render() {
    if (this.state.scriptLoading) {
      return null;
		}

    return (
		<MapComponent
			mapElement={<div style={{ height: `100%` }} />}
			{...this.props}
		/>
    );
  }
}

reactMixin(Map.prototype, ReactScriptLoaderMixin);

export default Map;
