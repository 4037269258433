// @flow
import { combineReducers } from 'redux';
import { polyglotReducer } from 'redux-polyglot';
import type { Reducer } from 'redux';
import type { RootState } from './flow/state';
import app from './common/app/reducer';
import registration from './common/registration/reducer';

const reducers: Reducer<RootState> = combineReducers({
  app,
  registration,
  polyglot: polyglotReducer,
});

export default reducers;
