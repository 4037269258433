// @flow
import React from "react";
import Switch from 'rc-switch';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';

type Props = {
  p: any;
  terminal: boolean;
  cash_registers: {[ value: string ]: string };
  payoutSystem: string;
  index: number;
  onPayoutSystemChange: (val: string) => void;
  onTerminalChange: (val: boolean) => void;
};

type State = {

};

export class PaymentMethods extends React.Component<Props, State> {
  state = {
	};

  static defaultProps = {};

  render() {
    const { p, terminal, cash_registers, payoutSystem, index } = this.props;
    return (
      <Row>
      <Col sm={6} lg={3}>
        <div className="form-group form-offset-top">
          <label className="form-control-static">{p.t('posterminal')}</label>
          <Switch
            checked={terminal}
            checkedChildren={p.t('yes')}
            unCheckedChildren={p.t('no')}
            onChange={this.props.onTerminalChange}
            className="pull-right"
          />
        </div>
      </Col>
      <Col sm={6} lg={3}>
        <div className="form-group">
          <label>{p.t('banksystem')}</label>
          <Select
            className="Select-form"
            resetValue="0"
            options={mapToList(cash_registers)}
            placeholder={p.t('select')}
            value={payoutSystem}
            onChange={(value) => this.props.onPayoutSystemChange(value.value)}
            id={"salepoint[" + index + "][paysystem]"} />
        </div>
      </Col>
    </Row>
    );
  }
}

export function mapToList(cashRegisters) {
  const allRegisters = [];
  for (let i in cashRegisters) {
    if (cashRegisters.hasOwnProperty(i)) {
      allRegisters.push({ value: i, label: cashRegisters[i] });
    }
  }

  return allRegisters;
}
