import { RSAA, getJSON } from 'redux-api-middleware';
import { getApiUrl } from '../api';
import { selectRegistrationProductCode, selectRegistrationAgentureCode } from '../registration/selectors';
import { changeField } from '../registration/actions';
import { initialStatePJO } from '../registration/reducer';

export const TOGGLE_SPINNER = 'TOGGLE_SPINNER';

export const FETCH_RESLISTS_REQUEST = 'FETCH_RESLISTS_REQUEST';
export const FETCH_RESLISTS_SUCCESS = 'FETCH_RESLISTS_SUCCESS';
export const FETCH_RESLISTS_FAILURE = 'FETCH_RESLISTS_FAILURE';

export function toggleSpinner() {
  return {
    type: TOGGLE_SPINNER,
  };
}

export const loadReslists = () => (dispatch, GetState) => {
  const state = GetState();
  
  const productCode = selectRegistrationProductCode(state);
  const agentureCode = selectRegistrationAgentureCode(state);

  if (productCode === null) {
    return; // reslists not needed yet
  }

  const data = {
    product_code: productCode,
    c30_dealer_code: agentureCode || undefined,
  };

  return dispatch({
    [RSAA]: {
      endpoint: getApiUrl(state, `registration/reslists`),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data),
      types: [
        FETCH_RESLISTS_REQUEST,
        {
          type: FETCH_RESLISTS_SUCCESS,
          payload: (action, state, res) => {
            return getJSON(res).then(json => processFetchedReslists(json, dispatch, GetState));
          },
        },
        FETCH_RESLISTS_FAILURE,
      ],
    },
  });
};

function processFetchedReslists(data, dispatch, GetState) {

	const { registration } = GetState();

  const banksArray = Object.keys(data.bank_codes).map(code => ({
    label: data.bank_codes[code],
    value: code,
  }));

  // TODO: refactor - remove changeFields actions and use FETCH_RESLISTS_SUCCESS and its payload
  dispatch(changeField({
    name: ['bank_codes'],
    value: banksArray,
  }));

  const aPaymentFrequency = Object.keys(data.payment_frequency).map(val => ({
    id: val,
    ...data.payment_frequency[val],
	}));
	aPaymentFrequency.sort((a, b) => (a.order || 0) - (b.order || 0));
	const maxSalePercent = data.c95_max_sale_perc !== undefined && data.c95_max_sale_perc !== null ? data.c95_max_sale_perc : initialStatePJO.maxSalePercent;
	const defaultPayoutFreq = aPaymentFrequency.find(f => f.default) || aPaymentFrequency[0];

  dispatch(changeField({
    name: ['payment_frequency'],
    value: aPaymentFrequency,
	}));

	// set default payout type
	if (defaultPayoutFreq && registration.get('payoutType') === null) {
		dispatch(changeField({
			name: ['payoutType'],
			value: defaultPayoutFreq.id,
		}));		
	}

  dispatch(changeField({
    name: ['maxSalePercent'],
    value: maxSalePercent,
  }));

  dispatch(changeField({
    name: ['cash_registers'],
    value: data.cash_registers,
  }));

  dispatch(changeField({
    name: ['affil_types'],
    value: data.affil_types,
  }));

  dispatch(changeField({
    name: ['pos_banks'],
    value: data.pos_banks,
  }));

  dispatch(changeField({
    name: ['pos_so'],
    value: data.pos_so,
  }));

  return {
    bank_codes: banksArray,
    payment_frequency: aPaymentFrequency,
    cash_registers: data.cash_registers,
    affil_types: data.affil_types,
    maxSalePercent,
  };
}
