// import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Geosuggest from 'react-geosuggest';
import reactMixin from 'react-mixin';
import { ReactScriptLoaderMixin } from 'react-script-loader';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import classnames from 'classnames';
import { GOOGLE_MAPS_KEY } from '../../browserConfig';
import { geosuggestBounds } from '../../../common/constants';


class GeosuggestField extends Component {

	state = {
		focused: false,
	};

  constructor(props) {
    super(props);
    this.state = {
      scriptLoading: true,
      scriptLoadError: false,
      scriptUrl: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_KEY}&libraries=places&language=cs`,
    };
  }

  onScriptLoaded() {
    this.setState({ scriptLoading: false });
  }

  onScriptError() {
    this.setState({ scriptLoading: false, scriptLoadError: true });
  }

  getScriptURL() {
    return this.state.scriptUrl;
  }

	handleFocus = () => {
		this.setState({ focused: true });
	};

	handleBlur = () => {
		this.setState({ focused: false });
	};

  render() {

    const { label, id, name, initialValue, showError, ...rest } = this.props;
    const { focused } = this.state;

    if (this.state.scriptLoading) {
      return null;
    }

    const filled = initialValue && initialValue.length > 0;

    return (
			<FormGroup controlId={id || name} validationState={ (!filled || showError) ? 'error' : null }>
			<ControlLabel
        className={classnames("control-label-floating", { 'active': filled || focused })}
      >{label}</ControlLabel>
        <Geosuggest
          id={id || name}
          placeholder=''
          {...rest}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          initialValue={initialValue}
          bounds={geosuggestBounds}
        />
        {showError && <div className="help-block">{showError}</div>}
      </FormGroup>
    );
  }
}

reactMixin(GeosuggestField.prototype, ReactScriptLoaderMixin);

export default GeosuggestField;
