import Helmet from 'react-helmet';
import React, { Component } from 'react';
import translate from 'redux-polyglot/translate';
import { WEB_URL } from '../browserConfig';

export class NotFound extends Component {
  render() {
    const { p } = this.props;

    return (
      <div className="site">
        <main id="content" role="main" className="site-content aligner">
          <Helmet
            title={p.t('errormeta')}
          />
          <div className="section-content aligner-item">
            <div className="container">
              <div className="box box-wide center-block text-center">
                <h1>{p.t('notfound')}</h1>
                <p className="lead offset-bottom-large">
                  {p.t('requestedpagecz')}<br />
                  {p.t('requestedpageen')}
                </p>
                <p>
                  <a href={WEB_URL} className="btn btn-accent btn-block btn-lg btn-icon-right">
                    {p.t('continuetohomepage')}
                    <span className="glyphicon glyphicon-menu-right" aria-hidden="true" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default translate({ polyglotScope: 'home' }, NotFound);
