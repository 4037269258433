import React, { Component } from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getP, getLocale } from 'redux-polyglot';
import { tRouteLocale } from '../createRoutes';


export class SubMenu extends Component {
  constructor() {
    super();
    this.state = {

    };
    this.checkIfUnverifiedAgentureCode = this.checkIfUnverifiedAgentureCode.bind(this);
  }
  checkIfUnverifiedAgentureCode(e) {
    if (this.props.stopForUnverifiedAgentureCode && this.props.unverifiedCodeFn !== undefined) {
      e.preventDefault();
      this.props.unverifiedCodeFn();
    }
  }
  render() {
    const { location, currentLocale, p } = this.props;
    let basicdatapath = tRouteLocale("/lang/registration/basic-data/", currentLocale);
    let shoppath = tRouteLocale("/lang/registration/shops/", currentLocale);
    let payoutpath = tRouteLocale("/lang/registration/payout", currentLocale);
    let recapitulationpath = tRouteLocale("/lang/registration/recapitulation", currentLocale);

    // console.log('propssubmenu',this.props);
    let navlinenumber = 1;
    if (this.props.startedShops) {
      navlinenumber = 2;
    }
    if (this.props.startedPayout) {
      navlinenumber = 3;
    }
    if (this.props.startedRecapitulation) {
      navlinenumber = 4;
    }


    return (

      <nav className="nav-progress-wrap">
        <ol className="nav-progress hidden-print">
          <li className={classnames({ active: location.pathname.includes(tRouteLocale('basic-data', currentLocale)) }, { completed: this.props.startedBasic })} >
            <Link to={basicdatapath} >
              <span className="nav-progress-badge">1</span>
              <span className="nav-progress-label">{p.t('basicdata')}</span>
            </Link>
          </li>
          <li className={classnames({ active: location.pathname.includes(tRouteLocale('shops', currentLocale)) }, { completed: this.props.startedShops })} >
            <Link to={shoppath} className={classnames({ "link-disabled": !this.props.startedShops })} onClick={this.checkIfUnverifiedAgentureCode}>
              <span className="nav-progress-badge">2</span>
              <span className="nav-progress-label">{p.t('shops')}</span>
            </Link>
          </li>
          <li className={classnames({ active: location.pathname.includes(tRouteLocale('payout', currentLocale)) }, { completed: this.props.startedPayout })} >
            <Link to={payoutpath} className={classnames({ "link-disabled": !this.props.startedPayout })} onClick={this.checkIfUnverifiedAgentureCode}>
              <span className="nav-progress-badge">3</span>
              <span className="nav-progress-label">{p.t('payout')}</span>
            </Link>
          </li>
          <li className={classnames({ active: location.pathname.includes(tRouteLocale('recapitulation', currentLocale)) }, { completed: this.props.startedRecapitulation })}>
            <Link to={recapitulationpath} className={classnames({ "link-disabled": !this.props.startedRecapitulation })} onClick={this.checkIfUnverifiedAgentureCode}>
              <span className="nav-progress-badge">4</span>
              <span className="nav-progress-label">{p.t('recapitulation')}</span>
            </Link>
          </li>
        </ol>
        <div className={"nav-progress-line nav-progress-line-" + navlinenumber}></div>
      </nav>
    );

  }

}

export default connect(state => ({
  startedRecapitulation: state.registration.startedRecapitulation,
  startedBasic: state.registration.startedBasic,
  startedShops: state.registration.startedShops,
  startedPayout: state.registration.startedPayout,
  currentLocale: getLocale(state),
  p: getP(state, { polyglotScope: 'registration' }),
}), )(SubMenu);
