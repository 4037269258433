import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { getP, getLocale } from 'redux-polyglot';
import Helmet from 'react-helmet';
import { Col, Row, Grid } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import classnames from 'classnames';

// import { tRouteLocale } from '../../browser/createRoutes';
import { myStaySend } from './actions';
import Header, { getNextLangIndex, LANGUAGES_SK } from '../app/Header.react';
import Footer from '../app/Footer.react';
import Notifications from '../app/Notifications.react';
import { toggleSpinner } from '../../common/app/actions';
import FieldGroup from '../app/components/FieldGroup';
import CheckboxField from '../app/components/CheckboxField';

const initialState = {
  finished: false,
  submitted: false,
  errors: {},
  firstname: '',
  surname: '',
  employeeNum: '',
  ibmEntity: '',
  ibmBranch: '',
  email: '',
  phone: '',
  captcha: null,
};

const IBM_ENTITIES = [
  'IBM International Services Centre s.r.o.',
  'IBM Slovensko, spol. s r.o.',
].map(t => ({ label: t, value: t }));

const IBM_BRANCHES = (locale) => [
  'Protifašistických bojovníkov 11, Košice',
  'Prievozská 2, Bratislava',
  'Hurbanova 9A, Banká Bystrica',
].map(t => ({ label:  t, value: t }));

class IbmMothersFormContainer extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
		toggleSpinner: PropTypes.func,
		type: PropTypes.oneOf(['ibm-no-card']),
	};

	static defaultProps = {
		type: 'ibm-no-card',
	};

  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState() {
    const { p, currentLocale } = this.props;
    return {
      ...initialState,
      ibmBranches: IBM_BRANCHES(currentLocale),
      phone: p.t('phoneprefix'),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentLocale !== this.props.currentLocale) {
      this.setState({
        ibmBranches: IBM_BRANCHES(nextProps.currentLocale),
      });
    }
  }

  handleSubmit = (e) => {
    const { p } = this.props;
    e.preventDefault();
    const errors = this.validate();

    this.setState({ errors, submitted: true, });

    if (Object.keys(errors).length) {
      console.log('errors', errors);
      return;
    }

    const data =  {
      type: this.props.type,
      name: this.state.firstname,
      surname: this.state.surname,
      employeeNum: this.state.employeeNum,
      ibmEntity: this.state.ibmEntity,
      ibmBranch: this.state.ibmBranch,
      email: this.state.email,
      phone: this.state.phone,
      token: this.state.captcha,
    };

    this.props.myStaySend(data).then(act => {
      if (act.error) {
        this.setState({
          errors: {
            submit: p.t('formsenderror'),
          },
      });
        return;
      }

      this.setState({ finished: true });
    });
  }

  handleReset = () => {
    this.setState(this.getInitialState());
  }

  handleChange = (updt) => {
    this.setState(updt, () => {
      if (this.state.submitted) {
        const errors = this.validate();
        this.setState({ errors });
      }
    });
  }

  validate() {
    const { p } = this.props;
    const values = this.state;
    const errors = {};

    if (values.firstname.length < 1) {
      errors.firstname = p.t('fieldismandatory');
    }

    if (values.surname.length < 1) {
      errors.surname = p.t('fieldismandatory');
    }

    if (values.email.length < 5) {
      errors.email = p.t('fieldismandatory');
    }

    if (values.employeeNum.length < 1) {
      errors.employeeNum = p.t('fieldismandatory');
    }

    if (values.ibmEntity.length < 1) {
      errors.ibmEntity = p.t('fieldismandatory');
    }

    if (values.ibmBranch.length < 1) {
      errors.ibmBranch = p.t('fieldismandatory');
    }

    if (!values.captcha) {
      errors.captcha = p.t('captchaismandatory');
    }

    if (!values.gdprterms) {
      errors.gdprterms = p.t('fieldismandatory');
    }

    return errors;
  }

  renderFinished() {
    const { p } = this.props;
    return (
      <section className="text-center" style={{minHeight: '50vh', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <h3 className="h2">{p.t('thankyoutext1')}</h3>
      </section>
    );
  }

  renderForm() {
    const { p } = this.props;

    return (
      <section className="section-content">
      <form
        onSubmit={this.handleSubmit}
        onReset={this.handleReset}
      >
      <Grid>
        <Row>
          <Col xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{paddingBottom: 30, maxWidth: 550}}>
            <h3 className="h2">
              {p.t('ibm-mothers.fillinform1')}
            </h3>
            <p dangerouslySetInnerHTML={{__html: p.t('ibm-mothers.fillinform2')}}/>
            <FieldGroup
              name="firstname"
              label={p.t("firstname")}
              value={this.state.firstname}
              onChange={val => this.handleChange({firstname: val})}
              error={this.state.errors.firstname}
            />
            <FieldGroup
              name="surname"
              label={p.t("surname")}
              value={this.state.surname}
              onChange={val => this.handleChange({surname: val})}
              error={this.state.errors.surname}
            />
            <FieldGroup
              name="employeeNum"
              label={p.t("employeeNum")}
              value={this.state.employeeNum}
              onChange={val => this.handleChange({employeeNum: val})}
              error={this.state.errors.employeeNum}
            />
            <div className={classnames("form-group", { "has-error": this.state.errors.ibmEntity })}>
              <label className="control-label-floating active">{p.t('ibmEntity')}</label>
              <Select
                className="Select-form"
                resetValue=""
                options={IBM_ENTITIES}
                searchable={false}
                placeholder={p.t('select')}
                value={this.state.ibmEntity}
                onChange={value => this.handleChange({ ibmEntity: value && value.value })}
                id="ibmEntity" />
              {this.state.errors.ibmEntity && <div className="help-block">{this.state.errors.ibmEntity}</div>}
            </div>
            <div className={classnames("form-group", { "has-error": this.state.errors.ibmBranch })}>
              <label className="control-label-floating active">{p.t('ibmBranch')}</label>
              <Select
                className="Select-form"
                resetValue=""
                options={this.state.ibmBranches}
                searchable={false}
                placeholder={p.t('select')}
                value={this.state.ibmBranch}
                onChange={value => this.handleChange({ ibmBranch: value && value.value })}
                id="ibmBranch" />
              {this.state.errors.ibmBranch && <div className="help-block">{this.state.errors.ibmBranch}</div>}
            </div>
            <FieldGroup
              name="email"
              label={p.t("email")}
              value={this.state.email}
              onChange={val => this.handleChange({email: val})}
              error={this.state.errors.email}
            />
            <FieldGroup
              name="phone"
              label={p.t("phone")}
              maxLength={20}
              value={this.state.phone}
              onChange={val => this.handleChange({phone: val})}
              error={this.state.errors.phone}
            />
            <CheckboxField
              name="gdprterms"
              value={this.state.gdprterms}
              onChange={val => this.handleChange({gdprterms: val})}
              error={this.state.errors.gdprterms}
            >
              <span>
                {p.t('gdprtext1')}
                <a href={p.t('gdprtermslink')} rel="noopener noreferrer" target="_blank" className="link">
                  {p.t('gdprtextlink')}
                </a>
                {p.t('gdprtext2')}
              </span>
            </CheckboxField>
            <div className={this.state.errors.captcha && "has-error"} style={{display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center', flexDirection: 'column'}}>
              <ReCAPTCHA
                sitekey="6Lf3vokUAAAAAI_maLLhjFZ296Jc0NbQlATTcySr"
                onChange={val => this.setState({captcha: val})}
              />
              {this.state.errors.captcha && <div className="help-block">{this.state.errors.captcha}</div>}
            </div>
            {this.state.errors.submit && <div className="has-error"><h3 className="text-center help-block">{this.state.errors.submit}</h3></div>}
          </div>
          </Col>
        </Row>
        <hr className="offset-top-none offset-bottom-large-xs-max offset-bottom-large" />
        <Row>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-around'}}>
            <button type="reset" className="btn btn-accent btn-accent-outline btn-lg btn-icon-left">
              <span className="glyphicon glyphicon-remove" aria-hidden="true" />
              {p.t('reset')}
            </button>
            <button type="submit" className="btn btn-accent btn-lg">
              <span className="btn-icon-right">
                {p.t('submit')} <span className="glyphicon glyphicon-send" aria-hidden="true" />
              </span>
            </button>
          </Col>
        </Row>
      </Grid>
      </form>
    </section>
    );
  }

  render() {
    const { location, p, currentLocale } = this.props;
    const langIdx = getNextLangIndex(currentLocale, LANGUAGES_SK);
    const nextLang = LANGUAGES_SK[langIdx].lang;
    const langLinks = {
      cs: 'ibm-maminky',
      sk: 'ibm-mamicky',
      en: 'ibm-moms',
    };

    return (
      <div className="site site-fixed-header">
        <Helmet title={p.t('mystayquestionnaire')}>
          {<meta name="description" content={p.t('metadescription')} />}
        </Helmet>
        <Notifications />
        <Header title={p.t('mystayquestionnaire')} p={p} location={location} currentLocale={currentLocale} noLink>
          <li>
            <Link to={`/${nextLang}/${langLinks[nextLang] || langLinks.sk}`}>{LANGUAGES_SK[langIdx].label}</Link>
          </li>
          <li role="separator" className="divider" />
          <li>
            <a href={p.t('headerSignInUrl')}>
              <span
                className="glyphicon glyphicon-user offset-right hidden-xs hidden-sm"
                aria-hidden="true"
              />
              <span className="hidden-md">{p.t('headerSignIn')}</span>
            </a>
          </li>
        </Header>
        <main id="content" role="main" className="site-content bg-gray-lighter">
          {this.state.finished ? this.renderFinished() : this.renderForm()}
        </main>
        <Footer p={p} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.mystay,
  currentLocale: getLocale(state),
  p: getP(state, { polyglotScope: 'mystay' }),
  pErrors: getP(state, { polyglotScope: 'errors' }),
});

const mapDispatchToProps = ({
  toggleSpinner, myStaySend
});

export default connect(mapStateToProps, mapDispatchToProps)(IbmMothersFormContainer);
