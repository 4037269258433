// @flow
import './RegistrationBasic.css';
import Helmet from 'react-helmet';
import SubMenu from './SubMenu.react';
import React from 'react';
import { toggleSpinner } from '../../common/app/actions';
import { changeField, selectMe, addMe, removeMe, addShop, checkDealerCode } from '../../common/registration/actions';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Modal, ButtonGroup, Button, Glyphicon, Col, Row, Grid, ListGroupItem, ListGroup } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { getP, getLocale } from 'redux-polyglot';
import { scrollTopOfForm, validateEmail, isFocusedInput, validateTelephoneNumber, startStickyHeader } from '../browserCommonFunctions';
import { INSTANCE_SK } from '../browserConfig';
import GeosuggestField from '../app/components/GeosuggestField';
import FieldGroup from '../app/components/FieldGroup';
import { ShopType, RegistrationState } from '../../flow/state';
import { tRouteLocale } from '../createRoutes';
import CheckboxField from '../app/components/CheckboxField';

type Props = {
  registration: RegistrationState,
};

export class RegistrationBasic extends React.Component<Props, {}> {
  
  legacyRefs: {[ key:string ]: any} = {};

  constructor(props) {
    super(props);
    this.state = {
      dicVatEnabled: INSTANCE_SK,
      showMakeMe: false,
      showMakeMeErrors: false,
      showRemoveMe: false,
      makeMeFirstName: '',
      makeMeLastName: '',
      makeMeEmail: '',
      removeMeIndex: '',
      errors: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.props.registration.ic) {
        this.props.router.push(`/`);
      }
      startStickyHeader();
    }, 100);

  }

  toggle = (whatToToggle) => {
    this.setState({ [whatToToggle]: !this.state[whatToToggle] });
  }

  recountErrors = () => {
    const { registration } = this.props;
    let stateErrors = [];

    if (registration.people.filter(person => person.selectedMe).size === 0) stateErrors.push('selectedMe');
    if (registration.ic.length === 0) stateErrors.push('ic');
    if (registration.name.length === 0) stateErrors.push('name');
    // if (registration.wholeAddress.length === 0) stateErrors.push('wholeAddress'); // address is not editable, so counting errors there is contra-productive
    if (registration.wrongAgentureCode && registration.agentureCode.length > 0) stateErrors.push('wrongAgentureCode');
    if (!validateEmail(registration.email)) stateErrors.push('email');
    if (!validateTelephoneNumber(registration.telephone)) stateErrors.push('telephone');
    if (!registration.product_code) stateErrors.push('product_code');

    return stateErrors;
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { registration, p } = this.props;

    let stateErrors = this.recountErrors();
    if (stateErrors.length > 0) {
      this.props.changeField({ name: 'showError', value: true });
      this.props.changeField({ name: 'showErrorHelpers', value: true });
      this.props.changeField({ name: 'showErrorText', value:
        stateErrors.includes('wrongAgentureCode') ? p.t('wrongAgentureCode') : p.t('formerror') });
      scrollTopOfForm();

      this.setState({ errors: stateErrors });
    } else {
      if (!registration.startedShops) {
        // TODO: add flow types for google address and shop!
        const oShopToAdd: ShopType = {
          name: registration.name,
          address: registration.wholeAddress,
          googleAddress: registration.googleAddress,
          telephoneForHosts: registration.telephone,
          emailForHosts: registration.email,
          website: '',
          contactPersonFirstname: '',
          contactPersonLastname: '',
          contactPersonId: -1,
          terminal: false,
          payoutSystem: '',
          idCashier: '',
          bank: 1,
          lat: registration.googleAddress.g01_gps_lat,
          lng: registration.googleAddress.g01_gps_lng,
          editingPosition: false,
          hardCodedPosition: false,
          googlePlaceId: registration.googleAddress.g01_google_place_id,
          pos: null,
          posValid: true,
        };

        this.props.addShop(oShopToAdd);
      }

      // set default r13_email
      this.props.changeField({ name: 'r13_email', value: registration.email });

      this.props.changeField({ name: 'startedShops', value: true });
      this.props.changeField({ name: 'showError', value: false });
      this.props.changeField({ name: 'showErrorHelpers', value: false });
      this.navigateToShops();
    }
  }

  navigateToShops() {
    this.props.router.push(tRouteLocale('/lang/registration/shops', this.props.currentLocale));
  }

  changeField = (e) => {
    this.handleFieldChange(e.target.value, e.target.name);
  }

  handleFieldChange = (value, name) => {
    this.props.changeField({ name, value });
  }

  changeStateField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  async verifyAgentureCode(e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    const { p } = this.props;
    const dealerCode = this.legacyRefs["dealerCode"].value;
    if (!verifyDealerCode(dealerCode) && this.legacyRefs["dealerCode"].value.length > 0) {
      this.props.changeField({ name: 'wrongAgentureCode', value: true });
      this.props.changeField({ name: 'showError', value: true });
      this.props.changeField({ name: 'showErrorText', value: p.t('validdealer') });
      return false;
    }

    this.props.toggleSpinner();
    this.props.checkDealerCode(dealerCode).then(() => this.props.toggleSpinner());
  }

  handleProductChange = (code) => {
    this.props.changeField({ name: 'product_code', value: code });
  }

  render() {
    const { location, registration, p } = this.props;
    const { errors } = this.state;

    const products = [
      { code: 'GK', label: p.t('product_name.GK'), glypth: 'cutlery' },
      { code: 'GF', label: p.t('product_name.GF'), glypth: 'heart' },
    ];

    if (INSTANCE_SK) {
      products.push({ code: 'RP', label: p.t('product_name.RP'), fa: 'hotel' });
    }

    return (
      <main id="content" role="main" className="site-content bg-gray-lighter">
        <Helmet title={p.t('basicdatameta')} />
        <div className="section-content">
          <Grid>
            <SubMenu location={location} {...registration} stopForUnverifiedAgentureCode={(registration.wrongAgentureCode && registration.agentureCode.length) ? true : false} unverifiedCodeFn={() => {
              this.props.changeField({ name: 'wrongAgentureCode', value: true });
              this.props.changeField({ name: 'showError', value: true });
              this.props.changeField({ name: 'showErrorText', value: p.t('wrongAgentureCode') });
            }} />
            <hr className="offset-top-none offset-bottom-large-xs-max offset-bottom-large" />
            <form onSubmit={this.onSubmit}>
              <Row className="offset-bottom">
                <Col sm={4} className="offset-bottom-large-xs-max">
                  <h2 className="offset-top-none">{p.t('datacompany')}</h2>
                  <FieldGroup
                    id="company_id"
                    type="number"
                    name="ic"
                    max="99999999"
                    min="0"
                    label={p.t('ic')}
                    value={registration.ic}
                    disabled
                  />
                    {/* onChange={this.handleFieldChange}
                    error={(registration.ic.length === 0 && registration.showErrorHelpers) && p.t('validcompanynumber')} */}
                  <FieldGroup
                    id="company_name"
                    name="name"
                    label={p.t('name')}
                    value={registration.name}
                    disabled
                  />
                    {/* onChange={this.handleFieldChange}
                    error={(registration.name.length === 0 && registration.showErrorHelpers) && p.t('validcompanyname')} */}
                    <GeosuggestField
                      name="wholeAddress"
                      id="geos"
                      label={p.t('address')}
                      initialValue={registration.wholeAddress}
                      inputClassName="form-control js-form-control"
                      onSuggestSelect={(e) => {
                        const googleAddress = getAddressModelFromGeosuggest(e);
                        this.props.changeField({ name: 'wholeAddress', value: e.label });
                        this.props.changeField({ name: 'googleAddress', value: googleAddress });
                      }}
                      disabled
                    />
                  <FieldGroup
                    id="company_tax_id"
                    name="dic"
                    label={p.t('dic')}
                    value={registration.dic}
                    disabled
                  />
                  {this.state.dicVatEnabled && <FieldGroup
                    id="company_tax_vat_id"
                    name="dic_vat"
                    label={p.t('dic_vat')}
                    value={registration.dic_vat}
                    disabled
                  />}
                </Col>
                <Col sm={4} className="offset-bottom-large-xs-max">
                  <h2 className="offset-top-none">{p.t('me')}</h2>
                  <div className="form-offset-top">
                    {registration.people.size > 0 && <div className="form-group">
                      <div className="btn-group-block btn-group-block-vertical" role="group" aria-label="Kontaktní osoba" data-toggle="buttons">
                        {registration.people.filter(person => !person.onlyForShop).map((person, indexPerson) => <label key={person.firstName + person.lastName + indexPerson} className={classnames("btn btn-default", { active: person.selectedMe })}
                          onClick={() => {
                            this.props.selectMe(indexPerson);
                          }}
                        >
                          <input type="radio" name="options" id="option1" autoComplete="off" />
                          {person.firstName} {person.lastName}
                          {person.addedByHand && <button type="button" style={{ zIndex: 9900 }} className="btn-link btn-link-inline link-unstyled pull-right"
                            onClick={(e) => {
                              this.setState({ removeMeIndex: indexPerson })
                              this.toggle('showRemoveMe');
                              setTimeout(() => {
                                this.legacyRefs['btn-remove-person'].focus();
                              }, 150);
                            }}>
                            <span className="glyphicon glyphicon-trash" aria-hidden="true"></span>
                          </button>}
                        </label>)}

                      </div>
                    </div>}
                    <div className="form-group">
                      <span className="btn btn-default btn-block" title="Přidat" id="addmebtn" onClick={(e) => {
                        e.preventDefault();
                        this.toggle('showMakeMe');
                        this.props.changeField({ name: 'showError', value: false });
                      }}>
                        <span className="btn-icon-left">
                          <span className="glyphicon glyphicon-plus" aria-hidden="true" />
                          {p.t('addperson')}
                        </span>
                      </span>
                    </div>
                    {errors.includes('selectedMe') && <div className="form-group has-error">
                      <div className="help-block">
                        {p.t('chooseyourself')}
                      </div>
                    </div>}

                    <span className="help-block ">{p.t('ares')}</span>
                  </div>
                </Col>
                <Col sm={4}>
                  <h2 className="offset-top-none">{p.t('contactdetails')}</h2>
                  <div className={classnames("form-group", { 'has-error': (!validateEmail(registration.email) && registration.showErrorHelpers) })}>
                    <label htmlFor="contact_email" className={classnames("control-label-floating", { active: registration.email.length > 0 || isFocusedInput('email') })}>{p.t('email')}</label>
                    <input value={registration.email} onChange={(e) => {
                      this.changeField(e);
                      this.props.changeField({
                        name: ['people', registration.people.filter(person => !person.onlyForShop).findIndex((person, indexPerson) => person.selectedMe), 'email'],
                        value: e.target.value,
                      });
                    }} name="email"
                      onFocus={() => { this.forceUpdate(); }} onBlur={() => { this.forceUpdate(); }}
                      type="email" id="contact_email" className="form-control js-form-control" />
                    <p className="help-block">
                      {p.t('emailtitle')}
                    </p>
                    {(!validateEmail(registration.email) && registration.showErrorHelpers) && <div className="help-block">
                      {p.t('inputvalidemail')}
                    </div>}
                  </div>
                  <div className={classnames("form-group", { 'has-error': (errors.includes('telephone') && !validateTelephoneNumber(registration.telephone)) })}>
                    <label htmlFor="contact_phone" className={classnames("control-label-floating", { active: registration.telephone.length > 0 || isFocusedInput('telephone') })}>{p.t('telephone')}</label>
                    <input value={registration.telephone} onChange={this.changeField} name="telephone"
                      onFocus={() => { this.forceUpdate(); }} onBlur={() => { this.forceUpdate(); }}
                      type="tel" id="contact_phone" className="form-control js-form-control" />
                    <p className="help-block">
                      {!(errors.includes('telephone') && !validateTelephoneNumber(registration.telephone)) ? p.t('optional') : p.t('validtelephone')}
                    </p>
                  </div>
                  <div className={classnames({ 'has-error': (errors.includes('product_code') && !registration.product_code) })}>
                    <h2>{p.t('productCodeSelectionTitle')}</h2>
                    <ButtonGroup
                      justified>
                      {products.map(prod =>
                        <ButtonGroup key={prod.code}>
                          <Button
                            bsSize="large"
                            className={classnames('product-button', prod.code === registration.product_code && 'active')}
                            onClick={() => this.handleProductChange(prod.code)}
                          >
                          {prod.fa ? <FontAwesome name={prod.fa} /> : <Glyphicon glyph={prod.glypth} />}
                          <div>{prod.label}</div></Button>
                        </ButtonGroup>)}
                    </ButtonGroup>
										{registration.product_code === 'GF' && (
											<CheckboxField
												name="rekreo_product"
												value={registration.rekreo_product}
												onChange={val => this.handleFieldChange(val, 'rekreo_product')}
												// error={showErrorHelpers && !this.state.confirmTerms && p.t('requiredfield')}
											>
												{p.t('productRekreoLabel')}
											</CheckboxField>)}
                    {!registration.product_code && errors.includes('product_code') && <p className="help-block">
                        {p.t('selectProduct')}
                    </p>}
                    <p className="help-block">{INSTANCE_SK ? p.t('productCodeSelectionNoteSK') : p.t('productCodeSelectionNote')}</p>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="offset-bottom-large">
                <Col sm={4}>
                  <div className={classnames("form-group", { 'has-error': (registration.wrongAgentureCode && registration.agentureCode.length > 0) })}>
                    <label htmlFor="dealer" className={classnames("control-label-floating", { active: registration.agentureCode.length > 0 || isFocusedInput('agentureCode') })}>{p.t('agencycode')}</label>
                    <div className="input-group">
                      <input type="text"
                        value={registration.agentureCode} onChange={this.changeField} name="agentureCode"
                        ref={r => this.legacyRefs["dealerCode"] = r}
                        onFocus={() => { this.forceUpdate(); }} onBlur={() => {
                          this.forceUpdate();
                          if (registration.agentureCode.length > 0) {
                            this.verifyAgentureCode();
                          }
                        }}
                        id="dealer" className="form-control js-form-control" />
                      <span className="input-group-btn">
                        <span className="btn btn-primary link" id="form-submit-agentureCode"
                          ref={r => this.legacyRefs["form-submit-agentureCode"] = r} type="button"
                          onClick={this.verifyAgentureCode.bind(this)}>{p.t('verify')}</span>
                      </span>
                    </div>
                    <p className="help-block">
                      {p.t('agency')}
                    </p>
                  </div>
                </Col>
                {!INSTANCE_SK && registration.bankAccountsFromAPI && registration.bankAccountsFromAPI.length > 0 && <Col smOffset={2} sm={6}>
                <h2>{p.t('bankAccountsTitle')}</h2>
                <ListGroup>
                  {registration.bankAccountsFromAPI.map((acc, idx) => (
                    <ListGroupItem key={idx}>
                      {(acc.k01_prefix !== null && acc.k01_prefix.length > 0) && acc.k01_prefix + '-'}
                      {acc.k01_number}&nbsp;/&nbsp;{acc.k10_bank_code}
                    </ListGroupItem>))}
                </ListGroup>
                </Col>}
              </Row>
              <hr className="offset-bottom-xs-max offset-bottom-large" />
              {/* Navigation */}
              <Row>
                <Col sm={5} smOffset={7} md={4} mdOffset={8}>
                  <button type="submit" className="btn btn-accent btn-block btn-lg"
                    ref={r => this.legacyRefs["form-submit-btn"] = r}>
                    <span className="btn-icon-right">
                      {p.t('inputshops')}
                      <span className="glyphicon glyphicon-menu-right" aria-hidden="true" />
                    </span>
                  </button>
                </Col>
              </Row>{/* .row */}
            </form>
          </Grid>
        </div>
        <Modal show={this.state.showMakeMe} onHide={() => {
          this.setState({
            showMakeMeErrors: false,
            showMakeMe: false,
          });
        }}>
          <form onSubmit={(e) => {
            e.preventDefault();
            if (this.state.makeMeFirstName.length === 0 || this.state.makeMeLastName.length === 0 || (
              this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail)
            )) {
              this.setState({ showMakeMeErrors: true });
              return false;
            }
            this.props.selectMe(-1);
            this.props.addMe({
              firstName: this.state.makeMeFirstName,
              lastName: this.state.makeMeLastName,
              email: this.state.makeMeEmail,
              selectedMe: true,
              addedByHand: true,
              isDeputy: true,
            });
            let newErrors = this.state.errors.filter(error => error !== 'selectedMe');
            this.setState({
              makeMeFirstName: '',
              makeMeLastName: '',
              makeMeEmail: '',
              errors: newErrors,
              showMakeMe: false,
              showMakeMeErrors: false,
            });
          }}>
            <Modal.Header closeButton>
              {p.t('addperson')}
            </Modal.Header>
            <Modal.Body>
              <div className={classnames("form-group", { "has-error": (this.state.showMakeMeErrors && this.state.makeMeFirstName.length === 0) })}>
                <label htmlFor="makeMeFirstName"
                  className={classnames("control-label-floating", { active: this.state.makeMeFirstName.length > 0 || isFocusedInput('makeMeFirstName') })}>{p.t('firstname')}</label>
                <input value={this.state.makeMeFirstName} onChange={this.changeStateField} name="makeMeFirstName"
                  onFocus={() => { this.forceUpdate(); }} onBlur={() => { this.forceUpdate(); }}
                  type="text" id="makeMeFirstName" className="form-control js-form-control" />
                {(this.state.showMakeMeErrors && this.state.makeMeFirstName.length === 0) && <div className="help-block">
                  {p.t('validfirstname')}
                </div>}
              </div>
              <div className={classnames("form-group", { "has-error": (this.state.showMakeMeErrors && this.state.makeMeLastName.length === 0) })}>
                <label htmlFor="makeMeLastName" className={classnames("control-label-floating", { active: this.state.makeMeLastName.length > 0 || isFocusedInput('makeMeLastName') })}>
                  {p.t('surname')}</label>
                <input value={this.state.makeMeLastName} onChange={this.changeStateField} name="makeMeLastName"
                  onFocus={() => { this.forceUpdate(); }} onBlur={() => { this.forceUpdate(); }}
                  type="text" id="makeMeLastName" className="form-control js-form-control" />
                {(this.state.showMakeMeErrors && this.state.makeMeLastName.length === 0) && <div className="help-block">
                  {p.t('validlastname')}
                </div>}
              </div>
              <div className={classnames("form-group", { "has-error": (this.state.showMakeMeErrors && (this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail))) })}>
                <label htmlFor="makeMeEmail" className={classnames("control-label-floating", { active: this.state.makeMeEmail.length > 0 || isFocusedInput('makeMeEmail') })}>
                  {p.t('email')}</label>
                <input value={this.state.makeMeEmail} onChange={this.changeStateField} name="makeMeEmail"
                  onFocus={() => { this.forceUpdate(); }} onBlur={() => { this.forceUpdate(); }}
                  type="text" id="makeMeEmail" className="form-control js-form-control" />
                {(this.state.showMakeMeErrors && (this.state.makeMeEmail.length > 0 && !validateEmail(this.state.makeMeEmail))) && <div className="help-block">
                  {p.t('inputvalidemail')}
                </div>}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary pull-right offset-left">{p.t('add')}</button>
              <button className="btn btn-default " onClick={(e) => {
                e.preventDefault();
                this.setState({
                  showMakeMeErrors: false,
                  showMakeMe: false,
                });
              }}>{p.t('cancel')}</button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal show={this.state.showRemoveMe} onHide={() => this.toggle('showRemoveMe')}>
          <Modal.Body>
            {p.t('deleteperson')}
          </Modal.Body>
          <Modal.Footer>
            <button ref={r => this.legacyRefs["btn-remove-person"] = r} className="btn btn-primary pull-right offset-left" onClick={() => {
              this.toggle('showRemoveMe');
              this.props.removeMe(this.state.removeMeIndex);
            }}>{p.t('delete')}</button>
            <button className="btn btn-default " onClick={() => this.toggle('showRemoveMe')}>{p.t('cancel')}</button>
          </Modal.Footer>
        </Modal>
      </main>);
  }
}

function verifyDealerCode(dealerCode) {
  const reg = /^[a-z0-9]+$/i;
  return reg.test(dealerCode);
}

function getAddressComponent(e, name) {
  let ret = e.gmaps.address_components.filter(comp => comp.types[0] === name)[0];
  return ret === undefined ? '' : ret.long_name;
}

function getAddressModelFromGeosuggest(e) {
  return {
    "g01_address_id": 2,
    "g50_street_name": getAddressComponent(e, 'route'),
    "g01_street_number": getAddressComponent(e, 'street_number'),
    "g01_premise": getAddressComponent(e, 'premise'),
    "g60_city_name": getAddressComponent(e, 'locality'),
    "g55_locality_name": getAddressComponent(e, 'locality'),
    "g30_postal_code": getAddressComponent(e, 'postal_code'),
    "g30_postal_town": getAddressComponent(e, 'postal_town'),
    "g70_district_name": getAddressComponent(e, 'administrative_area_level_2'),
    "g80_region_name": getAddressComponent(e, 'administrative_area_level_1'),
    "g90_country_name": getAddressComponent(e, 'country'),
    "g90_country_code": "CZ",
    "g01_gps_lat": e.location.lat,
    "g01_gps_lng": e.location.lng,
    "g01_google_place_id": e.gmaps.place_id
  };
}

const mapStateToProps = state => ({
  registration: state.registration,
  currentLocale: getLocale(state),
  p: getP(state, { polyglotScope: 'registration' }),
  pErrors: getP(state, { polyglotScope: 'errors' }),
});

const mapDispatchToProps = ({
  changeField, selectMe, addMe, removeMe, toggleSpinner, addShop, checkDealerCode
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationBasic);
