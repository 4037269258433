import React, { Component } from 'react';
import { connect } from 'react-redux';
import {changeField} from '../../common/registration/actions';
import classnames from 'classnames';

class Notifications extends Component {

  render() {
    const { showError, showErrorText, showErrorClass } = this.props;
    // if(!showError) return null;
    return (
      <div className={classnames('notification hidden-print',{active:showError})}>
        <div className={classnames('alert alert-'+showErrorClass+' alert-dismissible fade',{in:showError})} role="alert">
          <button type="button" className="close" onClick={()=>{
            this.props.changeField({name:'showError', value:false});
          }}><span aria-hidden="true">×</span></button>
          <span className="glyphicon glyphicon-exclamation-sign offset-right" aria-hidden="true" />
          {showErrorText}
        </div>
      </div>
    );
  }

}

export default connect(state => ({
  showError: state.registration.showError,
  showErrorText: state.registration.showErrorText,
  showErrorClass: state.registration.showErrorClass
}), { changeField })(Notifications);
